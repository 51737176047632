import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLang = "ro";
const domainDefLang = [
    {hostName: "localhost", defLang: "ro"},
    {hostName: "www.anticariatelisei.ro", defLang: "ro"},
    {hostName: "www.eliseiantique.com", defLang: "en"},
    {hostName: "www.eliseiantiques.com", defLang: "en"},
];

const defaultLanguage = domainDefLang.find(item => item.hostName === window.location.hostname).defLang;

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: defaultLanguage === undefined ? fallbackLang : defaultLanguage,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            // order and from where user language should be detected
            order: ['querystring','localStorage','cookie'],

            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)


        },
        resources: {
            en: {
                translation: {
                    currency: 'Currency',
                    copyright: 'Elisei Antiques Website',
                    routes: {
                        index: '/',
                        aboutus: '/AboutUs',
                        antiqueshop: '/AntiqueShop',
                        getaquote: '/GetAQuote/',
                        myplace: '/MyAntiques/',
                        getaquoteStep1: '',
                        getaquoteStep2: 'step2',
                        getaquoteStep3: 'step3',
                        getaquoteStep4: 'step4',
                        addProductStep1: '',
                        addProductStep2: 'step2',
                        addProductStep3: 'step3',
                        addProductStep4: 'step4',
                        addProductStep5: 'step5',
                        edit: '/Edit',
                        create: '/Create/',
                        list : '',
                        signin: '/SignIn/',
                        signup: '/SignUp/',
                        logout: '/Logout',
                        resetpassword: 'ResetPassword',
                        newpassword: 'NewPassword',
                        seller: 'Traider',
                        confirm: 'Confirm',
                        myprofile : '/MyProfile',
                        forbidden: '/Forbidden',
                        discover: '/Discover',
                        privacy: '/Privacy',
                    },
                    title: {
                        index: 'Home',
                        aboutus: 'About Us',
                        privacy: 'Privacy Policy',
                        antiqueshop: 'Antique Shop',
                        getaquote: 'Get A Quote for Antique Restoration',
                        myplace: 'My Antiques',
                        createProduct: 'Add product',
                        editProduct: 'Edit product',
                        signin: 'Sign In',
                        logout: 'Log out',
                        myprofile : 'My Profile',
                        listMyProducts : 'My Products',
                        discover: 'Discover',
                    },
                    language: 'Language',
                    steps: {
                        title: {
                            0: 'Contact Details',
                            1: 'Attache items',
                            2: 'Review Form',
                            3: 'Sent Results',
                        },
                        description: {
                            0: 'Enter your details',
                            1: 'Upload items detail and picture',
                            2: 'Review the list',
                            3: 'Sent Results',
                        }
                    },
                    Step: 'Step',
                    AllStepsCompleted: 'Tutti i passaggi sono stati completati: hai finito!',
                    products: 'Products',
                    shop: {
                        Buy: 'Buy',
                        More_Details: 'More Details',
                        items: {},
                        imageGallery: 'Image Gallery',
                        No_Products_Found: 'Ooops, there are no items to sell',
                        Come_Back_Later: 'Please do come back later!',
                        card: {
                            buttonText: 'More Details',
                            description: 'If you want to sell your antique items through this site, please click on the button below',
                            title: 'How do you find this shop?',
                            deleteTitle : 'Delete atricle',
                            deleteDescription : 'Are you sure you want to delete article {{id}} ?',
                            deleteSuccessfully : 'Product id {{id}} deleted successfully',
                            addButtonText : 'Add product',
                        },
                        cardDescription: 'Browse our online antique shop',
                        description: 'Browse our online antique shop',
                        keywords: 'Antique Shop, Restorations, Antique Furniture Restoration, Antique Chair Restoration, Artwork Restoration, Restorations, Restoration Offer, Refurbishment Offer, Antique Shop.',
                        openInFull: 'Click to open original size',
                        moreTitle: 'Next products',
                        edit: 'Edit',
                        delete: 'Delete',
                        cancel: 'Cancel',
                        close: 'Close',
                    },
                    forms: {
                        profileForm: {
                            labels: {
                                remove: 'Remove account'
                            },
                            alert : {
                                title : 'Remove account',
                                message : 'Are you sure you want to delete your account?',
                                removedMessage : 'Your account was removed successfully and will be redirected to start page',
                            }
                        },
                        contactForm: {
                            errors: {
                                id: 'Item number is required',
                                firstName: 'First Name is required',
                                lastName: 'Last Name is required',
                                email: 'Email is required',
                                phone: 'Phone no is required',
                                details: 'Details is required to between 10 and 500 characters long',
                            },
                            labels: {
                                item: 'Item No.',
                                firstName: 'First Name',
                                lastName: 'Last Name',
                                email: 'Email',
                                phone: 'Phone',
                                details: 'Details',
                            }
                        },
                        quoteForm: {
                            errors: {
                                firstName: 'First Name is required',
                                lastName: 'Last Name is required',
                                email: 'Email is required',
                                phone: 'Phone number is required',
                                details: 'Details is required, at least 10 chars, max 500',
                                location: 'Location is required, please choose from list',
                                item_name: 'Item Name is required',
                                item_width: 'Item Width is required',
                                item_height: 'Item Height is required',
                                item_image: 'Item image is required',
                                item_image_name: 'Image is required',
                                item_image_size: 'Size must be max 200 kb',
                            },
                            labels: {
                                firstName: 'First Name',
                                lastName: 'Last Name',
                                email: 'Email',
                                phone: 'Phone',
                                details: 'Details',
                                location: 'Location',
                                item_name: 'Item Name',
                                item_width: 'Width (cm)',
                                item_height: 'Height (cm)',
                                item_image: 'Item Image',
                                item_file_name: 'Item Image Name',
                                select_image: 'Select Image',
                            }
                        },
                        addForm: {
                            errors: {
                                name: 'Product name is required',
                                price: 'Product price is required',
                                currency: 'Price currency is required',
                                title: 'Title is required',
                                desc: 'Short description is required',
                                language: 'Language is required',
                                longDesc: 'Long Description is required',

                            },
                            labels: {
                                name: 'Article Name',
                                price: 'Price',
                                currency: 'Currency',
                                title: 'Title in {{language}}',
                                desc: 'Short description in {{language}}',
                                language: 'Language',
                                longDesc: 'Long Description in {{language}}',
                                select_image: 'Select Image',
                                save_product: 'Save product',
                                product_updated: 'Product with id: {{id}} was updated successfully',
                                product_created: 'Product with id: {{id}} was created successfully',
                            },
                            steps: {
                                title: {
                                    0: 'Product details',
                                    1: 'Product details',
                                    2: 'Product description',
                                    3: 'Product images',
                                    4: 'Review and Submit',
                                    5: 'Results',
                                },
                                description: {
                                    0: 'Product details',
                                    1: 'Product details',
                                    2: 'Product description',
                                    3: 'Product images',
                                    4: 'Review and Submit',
                                    5: 'Results',
                                }
                            }
                        },
                        signInForm : {
                            labels : {
                                or: 'OR',
                                signIn: 'Sign in',
                                signInWithSocial: 'Sign in with social',
                                signInWithFacebook: 'Sign In with Facebook',
                                signInWithGoogle: 'Sign In with Google',
                                emailAddress: 'Email address',
                                password: 'Password',
                                newPassword: 'New Password',
                                rememberMe: 'Remember me',
                                forgotPassword: 'Forgot password?',
                                signUp: 'Sign up',
                                reset : 'Reset password',
                                code: 'Confirmation code',
                                resetPasswordSuccessfully : 'Password was reset successfully. A confirmation code was sent to ',
                                resetPassword : 'Password reset',
                                newPasswordSuccessfully : 'Password changed successfully',
                                goToLogin : 'Take me to login',
                            },
                            errors : {
                                userName : 'The username must be a valid email format',
                                userNameRequired : 'The username is required',
                                password : 'The password has to be between 8 and 50 characters',
                                passwordMin : 'The password length has to be at least 8 characters long',
                                passwordMax : 'The password length has to be max 50 characters long',
                                passwordUppercase : 'Password must have uppercase characters',
                                passwordNumeric : 'The Password must have at least one number character',
                                passwordSymbol : 'The Password must have at least one symbol character',
                            }
                        },
                        signUpForm : {
                            labels : {
                                signUp: 'Sign up',
                                emailAddress: 'Email address',
                                password: 'Password',
                                name: 'Name',
                                familyName: 'Family Name',
                                givenName: 'Given Name',
                                phone: 'Phone number',
                                newsletter: 'I want to receive news and offers on the provided email address',
                                alreadyAccount: 'Already have an account? Sign In here',
                                code: 'Confirmation code',
                                confirmationTitle: 'Account confirmation',
                                confirm : 'Confirm',
                                sellerSignUp: 'Trader Sign up',
                                seller : 'I want to add be able to sell my own antiques on this site',
                                userDetails : 'User Details',
                                updateDetails : 'Update Details',
                                updatedSuccessfully : 'Details updated successfully',
                                createSellerAccount : 'Looking for a Trader account?',
                            },
                            errors : {
                                userName : 'The username must be a valid email format',
                                userNameRequired : 'The username is required',
                                password : 'The password has to be between 8 and 50 characters',
                                passwordMin : 'The password length has to be at least 8 characters long',
                                passwordMax : 'The password length has to be max 50 characters long',
                                passwordUppercase : 'The Password must have at least one uppercase character',
                                passwordNumeric : 'The Password must have at least one number character',
                                passwordSymbol : 'The Password must have at least one symbol character',
                                familyRequired : 'The family name is required',
                                givenRequired : 'The given name is required',
                                familyMin : 'The family name has to have at least 5 characters',
                                familyMax : 'The family name has to have at most 50 characters',
                                givenMin : 'The given name has to have at least 5 characters',
                                givenMax : 'The given name has to have at most 50 characters',
                                phoneMin : 'The phone number has to have at least 10 digits',
                                phoneMax : 'The phone number has to have at most 20 digits',
                            }
                        }

                    },
                    yes: 'Yes',
                    no: 'No',
                    next: 'Next',
                    back: 'Back',
                    submit: 'Submit',
                    reset: 'Reset',
                    sendForm: 'Send Form',
                    sendingForm: 'Sending form...',
                    error: 'Error',
                    info: 'Information',
                    add: 'Add',
                    remove: 'Remove',
                    send_another: 'Send another',
                    aboutUs: {
                        description: 'With over 15 years of experience in the field of antique restoration, perfected in Italy, we offer a wide range of services, starting from restorations to consultancy in the field of antiques.',
                        keywords: 'Antique Shop, Restorations, Antique Furniture Restoration, Antique Chair Restoration, Artwork Restoration, Restorations, Restoration Offer, Refurbishment Offer, Antique Shop.',
                        contactTitle: 'Contact',
                        description2: 'We restore and give a new appearance to the following categories of items: sofas, chests of drawers, chairs. We adapt our offer according to the personal needs of each client, offering high-quality services. If you want to ask for an opinion or a price offer, please use the "Get A Quote for Antique Restoration" section and go through the three simple steps.',
                        addressTitle: 'Contact Address',
                        addressDescription: 'You can find us in Dorohoi, Botosani, Romania',
                        workingHourTitle: 'Working hours',
                        workingHourDescription: 'Monday 09:00 - Friday 15:00',
                        cardDescription: 'You can find out more about us, our work and our services by clicking here',
                        portofolio: 'Portofoli0',
                        portofolioDescription: 'Our last restoration (before and after). Click on the expand icon to see it in full screen',
                    },
                    Discover : {
                        title: 'Discover the Charm and Value in Restoring Furniture Over Buying New',
                        cardDescription: 'Discover the Charm and Value in Restoring Furniture Over Buying New',
                        description: 'Discover the Charm and Value in Restoring Furniture Over Buying New',
                        keywords: 'Antique Shop, Restorations, Antique Furniture Restoration, Antique Chair Restoration, Artwork Restoration, Restorations, Restoration Offer, Refurbishment Offer, Antique Shop.',
                        text: 'In a world constantly chasing the latest trends, there\'s a timeless appeal and numerous benefits to restoring furniture instead of buying new. Here\'s why choosing restoration can be a rewarding and superior choice:',
                        point0:{
                            title:'Sustainability and Environmental Responsibility',
                            text0:'<strong>Eco-Friendly Choice:</strong> Restoring furniture reduces waste and the need for new resources. By choosing restoration, you contribute to a more sustainable world, reducing your carbon footprint and supporting environmental conservation.',
                            text1:'<strong>Combat Fast Furniture:</strong> The modern trend of \'fast furniture\' contributes to environmental harm. Restoring and reusing helps combat this disposable culture'
                        },
                        point1:{
                            title:'Unique History and Character',
                            text0:'<strong>Preserve Stories:</strong> Every piece of old furniture has a story. Restoring it keeps these stories alive, preserving a tangible piece of history that new furniture simply cannot offer.',
                            text1:'<strong>Distinctive Charm:</strong> Restored furniture brings a unique charm and character to your space. It offers a distinct style that stands out from mass-produced items.'
                        },
                        point2:{
                            title:'Quality and Craftsmanship',
                            text0:'<strong>Superior Craftsmanship:</strong> Older furniture is often made with a level of craftsmanship and attention to detail that is rare in today\'s mass-produced items.',
                            text1:'<strong>Durability:</strong> Many old pieces are made with high-quality materials and solid construction, meaning they can last much longer, especially when well-restored.'
                        },
                        point3:{
                            title:'Cost-Effective',
                            text0:'<strong>Save Money:</strong> Restoring furniture can be more cost-effective than buying new, especially when dealing with high-quality pieces.',
                            text1:'<strong>Increase Value:</strong> Well-restored antique or vintage pieces can increase in value over time, making them not just a purchase but an investment.'
                        },
                        point4:{
                            title:'Personalization and Creativity',
                            text0:'<strong>Tailor to Your Taste:</strong> Restoration allows you to personalize furniture to your taste and decor. You have the freedom to choose fabrics, finishes, and colors that reflect your style.',
                            text1:'<strong>Creative Fulfillment:</strong> The process of restoring furniture can be a fulfilling creative project, offering a sense of accomplishment that buying new cannot match.'
                        },
                        point5:{
                            title:'',
                        },
                    },
                    Index: {
                        welcome: 'Welcome on Elisei Antiques website!',
                        description: 'Repair, Restore or Renew your antiques with our help',
                        keywords: 'Antique Shop, Restorations, Antique Furniture Restoration, Antique Chair Restoration, Artwork Restoration, Restorations, Restoration Offer, Refurbishment Offer, Antique Shop'
                    },
                    ContactView: {
                        title: 'Contact form',
                        description: 'You can contact us using this form. Enter your details and a small description on how we can help you and we will going to contact you',
                        keywords: 'Antique Shop, Restorations, Antique Furniture Restoration, Antique Chair Restoration, Artwork Restoration, Restorations, Restoration Offer, Refurbishment Offer, Antique Shop.',
                    },
                    Quote: {
                        cardDescription: 'If you are interested in our services and want to obtain a quotation, you can use the following for by filling out all the required information in 3 easy steps.',
                        description: 'If you are interested in our services and want to obtain a quotation, you can use the following for by filling out all the required information in 3 easy steps.',
                        keywords: 'Antique Shop, Restorations, Antique Furniture Restoration, Antique Chair Restoration, Artwork Restoration, Restorations, Restoration Offer, Refurbishment Offer, Antique Shop.',
                    },
                    portofolio: {
                        items: []
                    },
                    noMatch: {
                        title: 'Not found',
                        description: 'No match for',
                        click: 'Click to navigate to first page'
                    },
                    loggedOut : {
                        title: 'You are logged out',
                        click: 'Click to navigate to first page',
                    },
                    Images: 'Images',
                    signUp : {
                        description : 'Repair, Restore or Renew your antiques with our help',
                        keywords: 'Antique Shop, Restorations, Antique Furniture Restoration, Antique Chair Restoration, Artwork Restoration, Restorations, Restoration Offer, Refurbishment Offer, Antique Shop.',
                    },
                    privacy : {
                        description : 'Repair, Restore or Renew your antiques with our help',
                        keywords: 'Antique Shop, Restorations, Antique Furniture Restoration, Antique Chair Restoration, Artwork Restoration, Restorations, Restoration Offer, Refurbishment Offer, Antique Shop.',
                    }
                }
            },
            ro: {
                translation: {
                    currency: 'Moneda',
                    copyright: 'Website Anticariat Elisei',
                    routes: {
                        index: '/',
                        aboutus: '/DespreNoi',
                        antiqueshop: '/MagazinAntichitati',
                        getaquote: '/CereOferta/',
                        myplace: '/AntichitatileMele/',
                        getaquoteStep1: '',
                        getaquoteStep2: 'pas2',
                        getaquoteStep3: 'pas3',
                        getaquoteStep4: 'pas4',
                        addProductStep1: '',
                        addProductStep2: 'pas2',
                        addProductStep3: 'pas3',
                        addProductStep4: 'pas4',
                        addProductStep5: 'pas5',
                        edit: '/Editeaza',
                        create: '/Adauga/',
                        list : '',
                        signin: '/Autentificare/',
                        signup: '/Inregistrare/',
                        logout: '/Delogare',
                        resetpassword: 'RecuperareParola',
                        newpassword: 'ParolaNoua',
                        seller: 'Comerciant',
                        confirm: 'Confirma',
                        myprofile: '/ProfilulMeu',
                        forbidden: '/Restrictionat',
                        discover: '/Descopera',
                        privacy: '/PoliticaDeConfidentialitate',
                    },
                    title: {
                        index: 'Prima Pagină',
                        aboutus: 'Despre Noi',
                        privacy: 'Politica de confidențialitate',
                        antiqueshop: 'Magazin antichitați',
                        getaquote: 'Cere ofertă de restaurare',
                        myplace: 'Antichitațiile mele',
                        createProduct: 'Adauga Articol',
                        editProduct: 'Editeaza Articol',
                        signin : 'Autentificare',
                        logout: 'Iesire din cont',
                        myprofile : 'Profilul Meu',
                        listMyProducts : 'Lista Antichitatilor mele',
                        discover: 'Descoperă',
                    },
                    language: 'Limba',
                    steps: {
                        title: {
                            0: 'Detalii de contact',
                            1: 'Atașați Obiectele',
                            2: 'Verifică Formularul',
                            3: 'Rezultat Trimitere',
                        },
                        description: {
                            0: 'Introduceți datele de contact',
                            1: 'Incărcați datele și imaginiea pentru articole',
                            2: 'Revizuiți lista înainte de trimitere',
                            3: 'Rezultat trimitere',
                        }
                    },
                    Step: 'Pas',
                    AllStepsCompleted: 'Toți pașii au fost parcurși! Mulțumim.',
                    StepDescription1: 'Introduceți datele de contact',
                    StepDescription2: 'Incărcați datele și imaginiea pentru articole',
                    StepDescription3: 'Revizuiți lista înainte de trimitere',
                    StepDescription4: 'Rezultat trimitere',
                    products: 'Produse',
                    shop: {
                        Buy: 'Cumpără',
                        More_Details: 'Mai multe detalii',
                        items: {},
                        imageGallery: 'Galeria de imagini',
                        No_Products_Found: 'Din păcate nu sunt produse',
                        Come_Back_Later: 'Vă rugăm reveniți mai tarziu',
                        card: {
                            buttonText: 'Mai multe detalii',
                            description: 'Dacă dorești să vinzi articole de anticariat pe acest site, te rugăm să apeși butonul de mai jos.',
                            title: 'Hey! Ești interesat?',
                            deleteTitle : 'Stergere articol',
                            deleteDescription : 'Sunteti sigur ca vreti sa stergeti atricolul {{id}} ?',
                            deleteSuccessfully : 'Articolul {{id}} s-a șters cu succes',
                            addButtonText : 'Adaugă articol',
                        },
                        cardDescription: 'Vizualizează magazinul nostru online de articole de anticariat și articole restaurate. Poate iți place ceva.',
                        description: 'Vizualizează magazinul nostru online de articole de anticariat și articole restaurate. Poate iți place ceva.',
                        keywords:'Anticariat, Restaurari, Reconditionare mobila antica, Reconditionare scaune antice, Reconditionare tablouri, Reconditionari, Oferta restaurare, Oferta reconditionare, magazin antichitati',
                        openInFull: 'Click pentru a deschide în mărimea originală',
                        moreTitle: 'Următoarele produse',
                        edit: 'Editează',
                        delete: 'Șterge',
                        cancel: 'Anulează',
                        close: 'Închide',
                    },
                    forms: {
                        profileForm: {
                            labels: {
                                remove: 'Șterge cont'
                            },
                            alert: {
                                title: 'Ștergere cont',
                                message: 'Sunteți sigur că doriți să ștergeți contul?',
                                removedMessage: 'Contul dumneavoastră a fost șters cu succes. Veți fi redirecționat la pagina de start',
                            }
                        },
                        contactForm: {
                            errors: {
                                id: 'Numărul articolului este obligatoriu',
                                firstName: 'Numele este obligatoriu',
                                lastName: 'Prenumele este obligatoriu',
                                email: 'Adresa de Email este obligatorie',
                                phone: 'Numărul de telefon este obligatoriu',
                                details: 'Detaliile sunt obligatorii și trebuie să conțină de la 10 până la 500 de caractere',
                            },
                            labels: {
                                item: 'Număr Articol',
                                firstName: 'Nume',
                                lastName: 'Prenume',
                                email: 'Email',
                                phone: 'Număr telefon',
                                details: 'Detalii',
                            }
                        },
                        quoteForm: {
                            errors: {
                                firstName: 'Numele este obligatoriu',
                                lastName: 'Prenumele este obligatoriu',
                                email: 'Adresa de Email este obligatoriu',
                                phone: 'Numărul de telefon este obligatoriu',
                                details: 'Detaliile sunt obligatorii și trebuie să conțină de la 10 până la 500 de caractere',
                                location: 'Locația este obligatorie',
                                item_name: 'Numele obiectului este necesara',
                                item_width: 'Lațimea obiectului este obligatorie',
                                item_height: 'Înalțimea obiectului este obligatorie',
                                item_image: 'Imaginea cu obiectul este obligatorie',
                                item_image_name: 'O imagine este necesara',
                                item_image_size: 'Marimea imaginii nu trebuie să depașească 200 kb',
                            },
                            labels: {
                                firstName: 'Nume',
                                lastName: 'Prenume',
                                email: 'Adresa Email',
                                phone: 'Numar telefon',
                                details: 'Detalii',
                                location: 'Locație',
                                item_name: 'Articol',
                                item_width: 'Lațime (cm)',
                                item_height: 'Înalțime (cm)',
                                item_image: 'Imagine articol',
                                item_file_name: 'Nume fișier',
                                select_image: 'Selectați imaginea',
                            }
                        },
                        addForm: {
                            errors: {
                                name: 'Numele articolului este obligatoriu',
                                price: 'Prețul este obligatoriu',
                                currency: 'Moneda este obligatorie',
                                title: 'Titlul este obligatoriu',
                                desc: 'Descrierea scurtă este obligatorie',
                                language: 'Limba este obligatorie',
                                longDesc: 'Descrierea detaliată este obligatorie',

                            },
                            labels: {
                                name: 'Nume articol',
                                price: 'Preț',
                                currency: 'Moneda',
                                title: 'Titlul în {{language}}',
                                desc: 'Descrierea scurtă în {{language}}',
                                language: 'Limba',
                                longDesc: 'Descrierea lungă în {{language}}',
                                select_image: 'Alege Imaginea',
                                save_product: 'Salvează produsul',
                                product_updated: 'Articolul cu numărul: {{id}} a fost actualizat cu succes',
                                product_created: 'Articolul cu numărul: {{id}} a fost creat cu succes',
                            },
                            steps: {
                                title: {
                                    0: 'Detalii articol',
                                    1: 'Descriere articol',
                                    2: 'Imagini articol',
                                    3: 'Revizuire',
                                    4: 'Rezultat',
                                },
                                description: {
                                    0: 'Detalii articol',
                                    1: 'Descriere articol',
                                    2: 'Imagini articol',
                                    3: 'Revizuire',
                                    4: 'Rezultat',
                                }
                            }
                        },
                        signInForm : {
                            labels : {
                                or: 'SAU',
                                signIn: 'Autentificare',
                                signInWithSocial: 'Autentificare cu retea sociala',
                                signInWithFacebook: 'Autentificare prin Facebook',
                                signInWithGoogle: 'Autentificare prin Google',
                                emailAddress: 'Adresa de email',
                                password: 'Parola',
                                newPassword: 'Parola Nouă',
                                rememberMe: 'Ține-mă minte',
                                forgotPassword: 'Ai uitat parola?',
                                signUp: 'Înregistrare',
                                reset : 'Resetează parola',
                                code: 'Codul de confirmare',
                                resetPasswordSuccessfully : 'Parola a fost resetată cu succes. Un cod de confirmare a fost trimis la ',
                                resetPassword : 'Resetare parola',
                                newPasswordSuccessfully : 'Parola a fost chimbată cu success',
                                goToLogin : 'Mergi la autentificare',
                            },
                            errors : {
                                userName : 'Numele de utilizator trebuie să aibă un format valid de adresă de email',
                                userNameRequired : 'Numele de utilizator este obligatoriu',
                                password : 'Parola este obligatorie',
                                passwordMin : 'Parola trebuie să aibă cel puțin 8 caractere',
                                passwordMax : 'Parola trebuie să aibă cel mult 50 de caractere',
                                passwordUppercase : 'Parola trebuie să aibă cel puțin o majusculă',
                                passwordNumeric : 'Parola trebuie să aibă cel puțin o cifra numerică',
                                passwordSymbol : 'Parola trebuie să aibă cel puțin un simbol din !@#$%^&*',
                            }
                        },
                        signUpForm : {
                            labels : {
                                signUp: 'Înregistrare',
                                emailAddress: 'Adresa de email',
                                password: 'Parola',
                                name: 'Nume',
                                familyName: 'Nume de familie',
                                givenName: 'Prenume',
                                phone: 'Număr de telefon',
                                newsletter: 'Vreau să fiu ținut la curent cu noutătile apărute pe acest site la adresa de email menționată mai sus.',
                                alreadyAccount: 'Cont existent? Autentificare aici',
                                code: 'Codul de confirmare',
                                confirmationTitle: 'Confirmarea contului',
                                confirm : 'Confirmă',
                                sellerSignUp: 'Înregistrare Comerciant',
                                seller : 'Doresc să vând articole de anticariat prin acest site',
                                userDetails : 'Detalii utilizator',
                                updateDetails : 'Actualizează detaliile',
                                updatedSuccessfully : 'Datele au fost actualizate cu succes',
                                createSellerAccount : 'Creare cont pentru comerciant?',
                            },
                            errors : {
                                userName : 'Numele de utilizator trebuie să aibă un format valid de adresă de email',
                                userNameRequired : 'Numele de utilizator este obligatoriu',
                                password : 'Parola este obligatorie',
                                passwordMin : 'Parola trebuie să aibă cel puțin 8 caractere',
                                passwordMax : 'Parola trebuie să aibă cel mult 50 de caractere',
                                passwordUppercase : 'Parola trebuie să aibă cel puțin o majusculă',
                                passwordNumeric : 'Parola trebuie să aibă cel puțin o cifra numerică',
                                passwordSymbol : 'Parola trebuie să aibă cel puțin un simbol din !@#$%^&*',
                                familyRequired : 'Numele de familie este obligatorie',
                                givenRequired : 'Prenumele este obligatorie',
                                familyMin : 'Numele de familie trebuie să aibă cel puțin 5 caractere',
                                familyMax : 'Numele de familie trebuie să aibă cel mult 50 de caractere',
                                givenMin : 'Prenumele trebuie să aibă cel puțin 5 caractere',
                                givenMax : 'Prenumele trebuie să aibă cel mult 50 de caractere',
                                phoneMin : 'Numărul de telefon trebuie să aibă cel puțin 10 cifre',
                                phoneMax : 'Numărul de telefon trebuie să aibă cel mult 20 cifre',
                            }
                        }
                    },
                    yes: 'Da',
                    no: 'Nu',
                    next: 'Înainte',
                    back: 'Înapoi',
                    submit: 'Trimite',
                    reset: 'Șterge',
                    sendForm: 'Trimite Formularul',
                    sendingForm: 'Formularul se trimite...',
                    error: 'Eroare',
                    info: 'Informații',
                    add: 'Adaugă',
                    remove: 'Sterge',
                    send_another: 'Trimite alta',
                    aboutUs: {
                        description: 'Cu peste 15 ani de experiența în domeniul restaurării antichitaților, perfecționat in Italia, vă punem la dispoziție o gamă largă de servicii, incepând de la restaurări pâna la consultanța în domeniul anticariatului.',
                        keywords:'Anticariat, Restaurari, Reconditionare mobila antica, Reconditionare scaune antice, Reconditionare tablouri, Reconditionari, Oferta restaurare, Oferta reconditionare, magazin antichitati',
                        description2: 'Restaurăm și dăm o nouă infațisare următoarelor categorii de articole: canapele, comode, scaune. Adaptăm oferta noastră în funcție de nevoile personale ale fiecărui client, oferind servicii de înalta calitate. ' +
                            'Dacă doriți să cereți o părere sau o ofertă de pret, vă rugăm folosiți secțiunea `CERE OFERTĂ DE RESTAURARE` și parcurgeți cei 3 pași simpli.',
                        contactTitle: 'Contact',
                        addressTitle: 'Adresa noastră',
                        addressDescription: 'Ne puteți găsi în Dorohoi, Botoșani, România',
                        workingHourTitle: 'Program de lucru',
                        workingHourDescription: 'Luni 09:00 - Vineri 15:00',
                        cardDescription: 'Pentru a afla mai multe despre noi și despre serviciile pe care le oferim te rugăm să apeși aici. De asemenea dacă vrei sa ne contactezi, găsești datele de contact aici.',
                        portofolio: 'Portofoliu',
                        portofolioDescription: 'Ultima restaurare (înainte și după).',
                    },
                    Discover : {
                        title: 'Descoperă Farmecul și Valoarea în Restaurarea Mobilierului în Loc de a Cumpăra Mobilă Nouă',
                        cardDescription: 'Descoperă Farmecul și Valoarea în Restaurarea Mobilierului în Loc de a Cumpăra Mobilă Nouă',
                        description: 'Descoperă Farmecul și Valoarea în Restaurarea Mobilierului în Loc de a Cumpăra Mobilă Nouă',
                        keywords:'Anticariat, Restaurari, Reconditionare mobila antica, Reconditionare scaune antice, Reconditionare tablouri, Reconditionari, Oferta restaurare, Oferta reconditionare, magazin antichitati',
                        text: 'Într-o lume care urmărește constant ultimele tendințe, există un farmec atemporal și numeroase beneficii în restaurarea mobilierului în loc de a cumpăra mobilă nouă. Iată de ce alegerea restaurării poate fi o opțiune gratifiantă și superioară:',
                        point0:{
                            title:'Sustenabilitate și Mediului Inconjurator',
                            text0:'<strong>Alegere Ecologică:</strong> Restaurarea mobilierului reduce deșeurile și necesitatea de resurse noi. Alegând restaurarea, contribuiți la un mediu mai sustenabil, reducând amprenta de carbon și susținând conservarea mediului.',
                            text1:'<strong>Lupta împotriva Mobilierului de Unică Folosință:</strong> Tendința modernă a \'mobilierului de unică folosință\' contribuie la daunele ambientale. Restaurarea și reutilizarea ajută la combaterea acestei culturi de consum.'
                        },
                        point1:{
                            title:'Istorie și Caracter Unice',
                            text0:'<strong>Păstrarea Poveștilor:</strong> Fiecare piesă de mobilier vechi are o poveste. Restaurând-o, mențineți vii aceste povești, conservând o parte tangibilă a istoriei pe care mobilierul nou pur și simplu nu o poate oferi.',
                            text1:'<strong>Farmec Distinctiv:</strong> Mobilierul restaurat aduce un farmec unic și caracter în spațiul dvs. Oferă un stil distinct care se diferențiază de articolele produse în masă.'
                        },
                        point2:{
                            title:'Calitate și Meșteșug',
                            text0:'<strong>Meșteșug Superior:</strong> Mobilierul mai vechi este adesea realizat cu un nivel de meșteșugărit și atenție la detalii rar în produsele de masă de astăzi.',
                            text1:'<strong>Durabilitate:</strong> Multe piese vechi sunt făcute cu materiale de înaltă calitate și o construcție solidă, ceea ce înseamnă că pot dura mult mai mult, mai ales atunci când sunt bine restaurate.',
                        },
                        point3:{
                            title:'Cost-Eficiență',
                            text0:'<strong>Economisiți Bani:</strong> Restaurarea mobilierului poate fi mai rentabilă decât cumpărarea de mobilă nouă, mai ales când se ocupă de piese de înaltă calitate.',
                            text1:'<strong>Creșterea Valorii:</strong> Piesele antice sau vintage bine restaurate pot crește în valoare în timp, transformându-le nu doar într-o achiziție, ci într-o investiție.',
                        },
                        point4:{
                            title:'Personalizare și Creativitate',
                            text0:'<strong>Adaptarea la Gustul Dvs.:</strong> Restaurarea vă permite să personalizați mobilierul conform gustului și decorului dvs. Aveți libertatea de a alege țesături, finisaje și culori care reflectă stilul dvs.',
                            text1:'<strong>Împlinire Creativă:</strong> Procesul de restaurare a mobilierului poate fi un proiect creativ satisfăcător, oferind un sentiment de realizare pe care cumpărarea de nou nu îl poate egala.',
                        },
                        point5:{
                            title:'Susținerea Afacerilor Locale',
                            text0:'<strong>Promovarea Meșteșugurilor Calificate:</strong> Alegerea de a restaura mobilierul implică adesea lucrul cu meșteri și profesioniști locali, susținând afacerile mici și competențele tradiționale.',
                            text1:''
                        },
                    },
                    ContactView: {
                        title: 'Formular contact',
                        description: "Dacă ești interesat de oferta noastra, folosește formularul de contact si noi te vom suna. În câmpul `Descriere` te rugăm să scrii pe scurt cu ce te putem ajuta.",
                        keywords:'Anticariat, Restaurari, Reconditionare mobila antica, Reconditionare scaune antice, Reconditionare tablouri, Reconditionari, Oferta restaurare, Oferta reconditionare, magazin antichitati',
                    },
                    Index: {
                        welcome: 'Bine ați venit pe site-ul Anticariat Elisei!',
                        description: 'Repară, recondiționează, restaurează, retapițează obiectele antice din casa ta cu ajutorul nostru.',
                        keywords:'Anticariat, Restaurari, Reconditionare mobila antica, Reconditionare scaune antice, Reconditionare tablouri, Reconditionari, Oferta restaurare, Oferta reconditionare, magazin antichitati',
                    },
                    Quote: {
                        cardDescription: 'Dacă sunteti interesați de servicii de restaurare, puteți să cereți o ofertă de restaurare completând formularul nostru în 3 pași simpli, durează doar câteva minute.',
                        description: 'Dacă sunteti interesați de servicii de restaurare, puteți să cereți o ofertă de restaurare completând formularul nostru în 3 pași simpli, durează doar câteva minute.',
                        keywords:'Anticariat, Restaurari, Reconditionare mobila antica, Reconditionare scaune antice, Reconditionare tablouri, Reconditionari, Oferta restaurare, Oferta reconditionare, magazin antichitati',
                    },
                    portofolio: {
                        items: []
                    },
                    noMatch: {
                        title: 'Inexistent',
                        description: 'Nu s-a găsit pagina',
                        click: 'Click pt a merge la prima pagină'
                    },
                    loggedOut : {
                        title: 'Ați fost delogat',
                        click: 'Click pt a merge la prima pagină',
                    },
                    Images: 'Imagini',
                    signUp : {
                        description:'Repară, recondiționează, restaurează, retapițează obiectele antice din casa ta cu ajutorul nostru.',
                        keywords:'Anticariat, Restaurari, Reconditionare mobila antica, Reconditionare scaune antice, Reconditionare tablouri, Reconditionari, Oferta restaurare, Oferta reconditionare, magazin antichitati',

                    },
                    privacy : {
                        description:'Repară, recondiționează, restaurează, retapițează obiectele antice din casa ta cu ajutorul nostru.',
                        keywords:'Anticariat, Restaurari, Reconditionare mobila antica, Reconditionare scaune antice, Reconditionare tablouri, Reconditionari, Oferta restaurare, Oferta reconditionare, magazin antichitati',

                    }
                }
            },
            it: {
                translation: {
                    currency: 'Moneta',
                    copyright: 'Sito web Elisei Antiquariato',
                    routes: {
                        index: '/',
                        aboutus: '/ChSiamo',
                        antiqueshop: '/NegozioDiAntiquariato',
                        getaquote: '/RichiediOfferta/',
                        myplace: '/MyAntiques/',
                        getaquoteStep1: '',
                        getaquoteStep2: 'passo2',
                        getaquoteStep3: 'passo3',
                        getaquoteStep4: 'passo4',
                        addProductStep1: '',
                        addProductStep2: 'passo2',
                        addProductStep3: 'passo3',
                        addProductStep4: 'passo4',
                        addProductStep5: 'passo5',
                        edit: '/Modificare',
                        create: '/Aggiungere/',
                        list : '',
                        signin: '/Registrazione/',
                        signup: '/Iscrizione/',
                        logout: '/Disconnettersi',
                        resetpassword: 'ResettaPassword',
                        newpassword: 'NuovaPassword',
                        seller: 'Venditore',
                        confirm: 'Confermare',
                        myprofile : '/IlMioProfilo',
                        forbidden: '/Forbidden',
                        discover: '/Scopri',
                        privacy: '/Privacy'
                    },
                    title: {
                        index: 'Prima Pagina',
                        aboutus: 'Chi Siamo',
                        privacy: 'Privacy Policy',
                        antiqueshop: 'Negozio di Antiquariato',
                        getaquote: 'Richiedi un preventivo di restauro',
                        myplace: 'I miei Antichi',
                        createProduct: 'Creare prodotto',
                        editProduct: 'Modifica prodotto',
                        signin : 'Registrazione',
                        logout: 'Disconnettersi',
                        myprofile : 'Il mio profilo',
                        listMyProducts : 'La mia lista dei prodotti',
                        discover: 'Scopri',

                    },
                    language: 'Lingua',
                    steps: {
                        title: {
                            0: 'Dettagli del contatto',
                            1: 'Allega gli oggetti',
                            2: 'Controlla il modulo',
                            3: 'Invia risultato',
                        },
                        description: {
                            0: 'Inserisci i tuoi dati di contatto',
                            1: 'Carica i dati e l\'immagine degli articoli',
                            2: 'Rivedere l\'elenco prima di inviare',
                            3: 'Risultato della presentazione',
                        }
                    },
                    Step: 'Passo',
                    AllStepsCompleted: 'Toți pașii au fost parcurși! Mulțumim.',
                    products: 'Prodotti',
                    shop: {
                        Buy: 'Acquistare',
                        More_Details: 'Più dettagli',
                        items: {},
                        imageGallery: 'Galleria di immagini',
                        No_Products_Found: 'Purtroppo non vengono prodotti',
                        Come_Back_Later: 'Per favore ritorna più tardi',
                        card: {
                            buttonText: 'Più dettagli',
                            description: 'Se desideri vendere oggetti d\'antiquariato su questo sito, fai clic sul pulsante qui sotto',
                            title: 'Ehi! Sei interessato?',
                            deleteTitle : 'Elimina articolo',
                            deleteDescription : 'Sei sicuro di voler eliminare l\'articolo {{id}} ?',
                            deleteSuccessfully : 'Articolo {{id}} cancellato',
                            addButtonText : 'Aggiungere prodotto',
                        },
                        description: 'Guarda il nostro negozio online per oggetti d\'antiquariato e restaurati. Forse ti piace qualcosa.',
                        keywords: 'Antiquariato, Restauri, Restauro Mobili Antichi, Restauro Sedie Antiche, Restauro Dipinti, Restauri, Offerta di Restauro, Offerta di Ricondizionamento, Negozio di Antichità.',
                        cardDescription: 'Guarda il nostro negozio online per oggetti d\'antiquariato e restaurati. Forse ti piace qualcosa.',
                        openInFull: 'Fare clic per aprire in formato originale',
                        moreTitle: 'Prossimi prodotti',
                        edit: 'Modificare',
                        delete: 'Elimina',
                        cancel: 'Annulla',
                        close: 'Chiudere',
                    },
                    forms: {
                        profileForm: {
                            labels : {
                                remove : 'Rimuovi conto'
                            },
                            alert : {
                                title : 'Rimuovi conto',
                                message : 'Sei sicuro di voler eliminare il tuo account?',
                                removedMessage : 'Il tuo account è stato eliminato con successo. Verrai reindirizzato alla pagina iniziale',
                            }
                        },
                        contactForm: {
                            errors: {
                                id: 'Il numero dell\'articolo è richiesto',
                                firstName: 'Il nome è obbligatorio',
                                lastName: 'Il nome è obbligatorio',
                                email: 'L\'indirizzo email è obbligatorio',
                                phone: 'Il numero di telefono è richiesto',
                                details: 'I dettagli sono obbligatori',
                            },
                            labels: {
                                item: 'Codice articolo',
                                firstName: 'Nome',
                                lastName: 'Nome di battesimo',
                                email: 'Email',
                                phone: 'Numero di telefono',
                                details: 'Particolari',
                            }
                        },
                        quoteForm: {
                            errors: {
                                firstName: 'Il nome è richiesto',
                                lastName: 'Il nome è richiesto',
                                email: 'L\'indirizzo e-mail è obbligatorio',
                                phone: '\'Il numero di telefono è richiesto',
                                details: 'Sono richiesti dettagli, almeno 10 caratteri, max 500',
                                location: 'La posizione è richiesta, si prega di scegliere dall\'elenco',
                                item_name: 'Il nome dell\'oggetto è richiesto',
                                item_width: 'La larghezza dell\'oggetto è richiesta',
                                item_height: 'È richiesta l\'altezza dell\'oggetto',
                                item_image: 'L\'immagine dell\'oggetto è richiesta',
                                item_image_name: 'È richiesta un\'immagine',
                                item_image_size: '\'La dimensione dell\'immagine non deve superare i 200 kb',
                            },
                            labels: {
                                firstName: 'Nome',
                                lastName: 'Nome',
                                email: 'Indirizzo e-mail',
                                phone: 'Numero di telefono',
                                details: 'Dettagli',
                                location: 'Posizione',
                                item_name: 'Articolo',
                                item_width: 'Larghezza (cm)',
                                item_height: 'Altezza (cm)',
                                item_image: 'Immagine oggetto',
                                item_file_name: 'Nome file',
                                select_image: 'Seleziona immagine',

                            },
                        },
                        addForm: {
                            errors: {
                                name: 'Il nome del prodotto è obbligatorio',
                                price: 'Il prezzo del prodotto è obbligatorio',
                                currency: 'La valuta del prezzo è obbligatoria',
                                title: 'Il titolo è obbligatorio',
                                desc: 'È richiesta una breve descrizione',
                                language: 'La lingua è richiesta',
                                longDesc: 'È richiesta una descrizione lunga',

                            },
                            labels: {
                                name: 'Nome dell\'articolo',
                                price: 'Prezzo',
                                currency: 'Moneta',
                                title: 'Titolo dentro {{language}}',
                                desc: 'Breve descrizione in {{language}}',
                                language: 'Lingua',
                                longDesc: 'Descrizione lunga in {{language}}',
                                select_image: 'Seleziona Immagine',
                                save_product: 'Salva prodotto',
                                product_updated: 'Il numero dell\'articolo: {{id}} è stato aggiornato con successo',
                                product_created: 'Numero articolo: {{id}} è stato creato con successo',
                            },
                            steps: {
                                title: {
                                    0: 'Dettagli del prodotto',
                                    1: 'Dettagli del prodotto',
                                    2: 'Descrizione del prodotto',
                                    3: 'Immagini del prodotto',
                                    4: 'Rivedi e invia',
                                    5: 'Risultati',
                                },
                                description: {
                                    0: 'Dettagli del prodotto',
                                    1: 'Dettagli del prodotto',
                                    2: 'Descrizione del prodotto',
                                    3: 'Immagini del prodotto',
                                    4: 'Rivedi e invia',
                                    5: 'Risultati',
                                }
                            }
                        },
                        signInForm : {
                            labels : {
                                or: 'OR',
                                signIn: 'Login',
                                signInWithSocial: 'Autenticazione con il social network',
                                signInWithFacebook: 'Accedi tramite Facebook',
                                signInWithGoogle: 'Accedi tramite Google',
                                emailAddress: 'Indirizzo e-mail',
                                password: 'Parola',
                                newPassword: 'Parola Noua',
                                rememberMe: 'Ricordati di me',
                                forgotPassword: 'Hai dimenticato la password?',
                                signUp: 'Inregistrare',
                                reset : 'Resetta la password',
                                code: 'Codice di conferma',
                                resetPasswordSuccessfully : 'La password è stata reimpostata con successo. È stato inviato un codice di conferma a ',
                                resetPassword : 'Reimpostazione della password',
                                newPasswordSuccessfully : 'La password è stata modificata con successo',
                                goToLogin : 'Portami al login',
                            },
                            errors : {
                                userName : 'Il nome utente deve essere un formato email valido',
                                userNameRequired : 'Il nome utente è obbligatorio',
                                password : 'La password è richiesta',
                                passwordMin : 'La lunghezza della password deve essere di almeno 8 caratteri',
                                passwordMax : 'La lunghezza della password deve essere di massimo 50 caratteri',
                                passwordUppercase : 'La Password deve contenere almeno un carattere maiuscolo',
                                passwordNumeric : 'La password deve contenere caratteri numerici',
                                passwordSymbol : 'La Password deve contenere almeno un carattere simbolo !@#$%^&*',
                            }
                        },
                        signUpForm : {
                            labels : {
                                signUp: 'Iscriviti',
                                emailAddress: 'Indirizzo e-mail',
                                password: 'Password',
                                name: 'Nome',
                                familyName: 'Cognome',
                                givenName: 'Nome di battesimo',
                                phone: 'Numero di telefono',
                                newsletter: 'Voglio ricevere ispirazione, promozioni di marketing e aggiornamenti via e-mail.',
                                alreadyAccount: 'Hai già un account? Accedi qui',
                                code: 'Codice di conferma',
                                confirmationTitle: 'Conferma dell\'account',
                                confirm : 'Confermare',
                                sellerSignUp: 'Registrati commerciante',
                                seller : 'Voglio aggiungere la possibilità di vendere i miei oggetti d\'antiquariato su questo sito',
                                userDetails : 'Dettagli utente',
                                updateDetails : 'Aggiorna dettagli',
                                updatedSuccessfully : 'Dettagli aggiornati con successo',
                                createSellerAccount : 'Cerchi un conto commerciante?',
                            },
                            errors : {
                                userName : 'Il nome utente deve essere un formato email valido',
                                userNameRequired : 'Il nome utente è obbligatorio',
                                password : 'La password deve contenere da 8 a 50 caratteri',
                                passwordMin : 'La lunghezza della password deve essere di almeno 8 caratteri',
                                passwordMax : 'La lunghezza della password deve essere di massimo 50 caratteri',
                                passwordUppercase : 'La Password deve contenere almeno un carattere maiuscolo',
                                passwordNumeric : 'La password deve contenere caratteri numerici',
                                passwordSymbol : 'La Password deve contenere almeno un carattere simbolo !@#$%^&*',
                                familyRequired : 'Il cognome è obbligatorio',
                                givenRequired : 'Il nome è obbligatorio',
                                familyMin : 'Il cognome deve contenere almeno 5 caratteri',
                                familyMax : 'Il cognome deve contenere al massimo 50 caratteri',
                                givenMin : 'Il nome deve contenere almeno 5 caratteri',
                                givenMax : 'Il nome deve contenere al massimo 50 caratteri',
                                phoneMin : 'Il numero di telefono deve avere almeno 10 cifre',
                                phoneMax : 'Il numero di telefono deve avere al massimo 20 cifre',
                            }
                        }
                    },
                    yes: 'Si',
                    no: 'No',
                    next: 'Prima',
                    back: 'Indietro',
                    submit: 'Invia',
                    reset: 'Elimina',
                    sendForm: 'Invia modulo',
                    sendingForm: 'Il modulo è in fase di invio...',
                    error: 'Errore',
                    info: 'Informazioni',
                    add: 'Aggiungi',
                    remove: 'Elimina',
                    send_another: 'Invia un altro',
                    aboutUs: {
                        keywords: 'Antiquariato, Restauri, Restauro Mobili Antichi, Restauro Sedie Antiche, Restauro Dipinti, Restauri, Offerta di Restauro, Offerta di Ricondizionamento, Negozio di Antichità.',
                        description: 'Con oltre 15 anni di esperienza nel campo del restauro di oggetti d\'antiquariato, perfezionata in Italia, ti offriamo una vasta gamma di servizi, dai restauri alla consulenza nel campo dell\'antiquariato.',
                        description2: 'Restauriamo e diamo un nuovo look alle seguenti categorie di articoli: divani, cassettiere, sedie. Adattiamo la nostra offerta in base alle esigenze personali di ogni cliente, offrendo servizi di alta qualità.' +
                            'Se desideri richiedere un parere o un preventivo, utilizza la sezione `RICHIEDI PREVENTIVO DI RIPRISTINO\' e segui i 3 semplici passaggi.',
                        contactTitle: 'Contatto',
                        addressTitle: 'Il nostro indirizzo',
                        addressDescription: 'Ci puoi trovare a Dorohoi, Botoșani, Romania',
                        workingHourTitle: 'Orario di lavoro',
                        workingHourDescription: 'Lunedì 09:00 - Venerdì 15:00',
                        cardDescription: 'Per saperne di più su di noi e sui servizi che offriamo, clicca qui. Inoltre, se vuoi contattarci, puoi trovare i dettagli di contatto qui.',
                        portofolio: 'Portafoglio',
                        portofolioDescription: 'Ultimo restauro (prima e dopo).',
                    },
                    Discover : {
                        title: 'Scopri il Fascino e il Valore nel Restaurare Mobili invece di Acquistarne di Nuovi',
                        cardDescription: 'Scopri il Fascino e il Valore nel Restaurare Mobili invece di Acquistarne di Nuovi',
                        description: 'Scopri il Fascino e il Valore nel Restaurare Mobili invece di Acquistarne di Nuovi',
                        keywords: 'Antiquariato, Restauri, Restauro Mobili Antichi, Restauro Sedie Antiche, Restauro Dipinti, Restauri, Offerta di Restauro, Offerta di Ricondizionamento, Negozio di Antichità.',
                        text: 'In un mondo in continua corsa dietro alle ultime tendenze, c\'è un fascino senza tempo e numerosi vantaggi nel restaurare mobili anziché acquistarne di nuovi. Ecco perché scegliere il restauro può essere una scelta gratificante e superiore:',
                        point0:{
                            title:'Sostenibilità e Responsabilità Ambientale',
                            text0:'<strong>Scelta Ecologica:</strong> Restaurare mobili riduce gli sprechi e la necessità di nuove risorse. Scegliendo il restauro, contribuisci a un mondo più sostenibile, riducendo l\'impronta di carbonio e sostenendo la conservazione ambientale.',
                            text1:'<strong>Combattere il Mobiliario Usa e Getta:</strong> La tendenza moderna del \'mobiliario usa e getta\' contribuisce al danno ambientale. Restaurare e riutilizzare aiuta a combattere questa cultura del monouso.'
                        },
                        point1:{
                            title:'Storia e Carattere Unici',
                            text0:'<strong>Preservare le Storie:</strong> Ogni pezzo di mobiliario antico ha una storia. Restaurarlo mantiene vive queste storie, conservando un pezzo tangibile di storia che i nuovi mobili semplicemente non possono offrire.',
                            text1:'<strong>Fascino Distintivo:</strong> I mobili restaurati portano un fascino unico e carattere nel tuo spazio. Offrono uno stile distintivo che si distingue dagli articoli prodotti in serie.'
                        },
                        point2:{
                            title:'Qualità e Artigianalità',
                            text0:'<strong>Artigianalità Superiore:</strong> I mobili più vecchi sono spesso realizzati con un livello di artigianalità e attenzione ai dettagli raro negli articoli prodotti in massa di oggi.',
                            text1:'<strong>Durata:</strong> Molti pezzi antichi sono fatti con materiali di alta qualità e una costruzione solida, il che significa che possono durare molto più a lungo, soprattutto se ben restaurati.'
                        },
                        point3:{
                            title:'Convenienza Economica',
                            text0:'<strong>Risparmia Denaro:</strong> Restaurare i mobili può essere più conveniente che acquistarne di nuovi, specialmente quando si tratta di pezzi di alta qualità.',
                            text1:'<strong>Aumento del Valore:</strong> I pezzi antichi o vintage ben restaurati possono aumentare di valore nel tempo, rendendoli non solo un acquisto ma un investimento.'
                        },
                        point4:{
                            title:'Personalizzazione e Creatività',
                            text0:'<strong>Adattarli al Tuo Gusto:</strong> Il restauro ti permette di personalizzare i mobili secondo il tuo gusto e arredamento. Hai la libertà di scegliere tessuti, finiture e colori che riflettono il tuo stile.',
                            text1:'<strong>Realizzazione Creativa:</strong> Il processo di restauro dei mobili può essere un progetto creativo appagante, offrendo un senso di realizzazione che l\'acquisto di nuovi non può eguagliare.'
                        },
                        point5:{
                            title:'',
                        },
                    },
                    ContactView: {
                        title: 'Modulo di contatto',
                        description: "Se sei interessato alla nostra offerta, usa il modulo di contatto e ti chiameremo. Nel campo 'Descrizione' scrivi brevemente in cosa possiamo aiutarti.",
                        keywords: 'Antiquariato, Restauri, Restauro Mobili Antichi, Restauro Sedie Antiche, Restauro Dipinti, Restauri, Offerta di Restauro, Offerta di Ricondizionamento, Negozio di Antichità.'
                    },
                    Index: {
                        welcome: 'Benvenuto nel sito dell\'Anticariat Elisei!',
                        description: 'Riparare, rinnovare, restaurare, rifoderare oggetti d\'antiquariato nella tua casa con il nostro aiuto.',
                        keywords: 'Antiquariato, Restauri, Restauro Mobili Antichi, Restauro Sedie Antiche, Restauro Dipinti, Restauri, Offerta di Restauro, Offerta di Ricondizionamento, Negozio di Antichità.'
                    },
                    Quote: {
                        cardDescription: 'Se sei interessato ai servizi di restauro, puoi richiedere un preventivo di restauro compilando il nostro modulo in 3 semplici passaggi, bastano pochi minuti.',
                        description: 'Se sei interessato ai servizi di restauro, puoi richiedere un preventivo di restauro compilando il nostro modulo in 3 semplici passaggi, bastano pochi minuti.',
                        keywords: 'Antiquariato, Restauri, Restauro Mobili Antichi, Restauro Sedie Antiche, Restauro Dipinti, Restauri, Offerta di Restauro, Offerta di Ricondizionamento, Negozio di Antichità.'
                    },
                    portofolio: {
                        items: []
                    },
                    noMatch: {
                        title: 'Inesistente',
                        description: 'Non è stata trovata la pagina',
                        click: 'Clicca per andare alla prima pagina'
                    },
                    loggedOut : {
                        title: 'You were logged out',
                        click: 'Clicca per andare alla prima pagina',
                    },
                    Images: 'Imagini',
                    signIn : {
                        description: 'Se sei interessato ai servizi di restauro, puoi richiedere un preventivo di restauro compilando il nostro modulo in 3 semplici passaggi, bastano pochi minuti.',
                        keywords: 'Antiquariato, Restauri, Restauro Mobili Antichi, Restauro Sedie Antiche, Restauro Dipinti, Restauri, Offerta di Restauro, Offerta di Ricondizionamento, Negozio di Antichità.'

                    },
                    privacy : {
                        description: 'Se sei interessato ai servizi di restauro, puoi richiedere un preventivo di restauro compilando il nostro modulo in 3 semplici passaggi, bastano pochi minuti.',
                        keywords: 'Antiquariato, Restauri, Restauro Mobili Antichi, Restauro Sedie Antiche, Restauro Dipinti, Restauri, Offerta di Restauro, Offerta di Ricondizionamento, Negozio di Antichità.'

                    }
                }
            },
        }
    });

export {i18n, defaultLanguage};
