import React, {useState} from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { brown } from '@mui/material/colors';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });



const AntiqueTheme = (props) => {
    const [mode, setMode] = useState('light');

    const theme = createTheme({
        typography: {
            fontFamily: [
                'Georgia',
                'Times New Roman',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        palette: {
            primary: {
                main: brown[500],
            },
            mode: mode,
            secondary: {
                main: '#665d5d',
            },
            spacing:4
        },
    });

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                {props.children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export { AntiqueTheme, ColorModeContext}