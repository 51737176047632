import React, {useState} from "react";
import AntiqueBackdrop from "../AntiqueBackdrop/AntiqueBackdrop";
import AntiqueDialog from "../AntiqueDialog/AntiqueDialog";
import {useTranslation} from "react-i18next";
import {DialogActions, DialogContent, DialogContentText} from "@mui/material";
import Button from "@mui/material/Button";
import {removeAccount} from "../../services/cognito";
import {AppRoute} from "../../const";
import {useNavigate} from "react-router-dom";
import Alert from "@mui/material/Alert";

const AntiqueRemoveAccount = () => {

    const navigate = useNavigate();
    const [error, setError] = useState(null)
    const [removed, setRemoved] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleRemoveAccount = ()=>{
        setLoading(true)
        setOpen(false)
        removeAccount().then(()=>{
            setLoading(false)
            setOpen(true)
            setRemoved(true)
        }).catch((err)=>{
            setRemoved(true)
            setOpen(true)
            setLoading(false)
            setError(err.message)
        })
    }

    const [t] = useTranslation();

    const handleClose = () => {
        setOpen(false)
    }

    const handleClick = () => {
        setOpen(true)
    }

    const handleConfirm = () => {
        navigate("./.."+t(AppRoute.SignOut));
    }

    return (
        <>
            <AntiqueBackdrop loading={loading} color={"inherit"} text={t('sendingForm')}/>
            <AntiqueDialog
                title={t('forms.profileForm.alert.title')}
                open={open}
            >
                {
                    removed ?
                        (
                            <>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {
                                        error ? (<Alert severity="error">{error}</Alert>) : (t('forms.profileForm.alert.removedMessage'))
                                    }

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleConfirm}>{t('ok')}</Button>
                            </DialogActions>
                            </>
                        )
                        :
                        (<>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t('forms.profileForm.alert.message')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleRemoveAccount}>{t('yes')}</Button>
                                <Button onClick={handleClose} autoFocus>
                                    {t('no')}
                                </Button>
                            </DialogActions>
                        </>)
                }

            </AntiqueDialog>
            <Button onClick={handleClick}>
                {t('forms.profileForm.labels.remove')}
            </Button>
        </>
    )
}

export default AntiqueRemoveAccount