import axios from "axios";
import {REST_API_ENDPOINT} from "../../const";
import {gql, useMutation} from '@apollo/client';
import {useContext} from "react";
import {UserContext} from "../../contexts/UserContext";


export const useAntiqueProductForm = () => {

    const userAuth = useContext(UserContext)
    axios.interceptors.request.use(
        config => {
            config.headers['Authorization'] = `Bearer ${userAuth.JwtToken}`;
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    const CREATE_PRODUCT_MUTATION = gql`
        mutation CreateProduct($currency: Currency!, $name: String!, $price: Float!, $id : ID) {
            addProduct(currency: $currency, name: $name, price: $price, id :$id) {
                id
            }
        }
    `;
    const CREATE_IMAGE_MUTATION = gql`
        mutation CreateImage($normal: String!, $productId: ID!, $imageId : ID) {
            addImage(normal: $normal, productId: $productId, imageId: $imageId) {
                productId
            }
        }
    `;
    const CREATE_DESCRIPTION_MUTATION = gql`
        mutation CreateDescription($desc: String!, $language: Language!, $longDesc: String, $productId: ID!, $title: String!, $descriptionId : ID) {
            addDescription(desc: $desc,language: $language,longDesc: $longDesc, productId: $productId, title: $title, descriptionId: $descriptionId) {
                productId
            }
        }
    `;
    const DELETE_IMAGE_MUTATION = gql`
        mutation RemoveImage($imageId : ID!, $productId : ID!) {
          removeImageWith(imageId: $imageId, productId: $productId) {
            imageId
          }
        }
    `;
    const [addProduct] = useMutation(CREATE_PRODUCT_MUTATION);
    const [addImage] = useMutation(CREATE_IMAGE_MUTATION);
    const [addDescription] = useMutation(CREATE_DESCRIPTION_MUTATION);
    const [removeImage] = useMutation(DELETE_IMAGE_MUTATION);



    const onError = (id, error, resetImage, setError) => {
        resetImage(id);
        setError(error.toString());
    }


    const uploadAsync = async (image, setUploadingImage, resetImage, setError, productId, idx) => {

        const onUploadProgress = (event) => {
            const percentage = Math.round((100 * event.loaded) / event.total);
            setUploadingImage(idx, percentage);
        };

        function base64ToArrayBuffer(base64) {
            var binary_string = window.atob(base64);
            var len = binary_string.length;
            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        }


        setUploadingImage(idx, 0);
        let arrayBuffer = base64ToArrayBuffer(image.content)
        let blobData = new Blob([arrayBuffer], {type: image.fileData.type});

        let config = {
            url: REST_API_ENDPOINT + `/image/item` + productId + `/` + image.fileData.name,
            method: 'put',
            data: blobData,
            onUploadProgress: onUploadProgress,
            headers: {
                'Content-Type': image.fileData.type,
            }
        }
        return axios(config).then(() => {
            resetImage(idx)
            return {productId: productId}
        }).catch((e) => {
            onError(idx, e, resetImage, setError)
            throw e
        });
    }





    const uploadImages = async (images, setUploadingImage, resetImage, setError, productId) => {
        if (productId === undefined) throw new Error('Invalid product Id for image')
        const promises = [];
        images.map((image, idx) => {
            if (image.content.length === 0) return false
            return promises.push(new Promise((resolve, reject) => {

                try {
                    uploadAsync(image, setUploadingImage, resetImage, setError, productId, idx).then((r)=>{
                        resolve(r)
                    }).catch((e)=>{
                        reject(e)
                    })
                } catch (e) {
                    reject(e)
                }
            }))

        })

        return Promise.all(promises);
    }

    return {uploadImages, addDescription, addImage, addProduct, removeImage}
}