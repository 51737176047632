import React, {useContext} from 'react';
import {FormInput} from "../FormInput/FormInput";
import * as yup from "yup";
import {FormTextArea} from "../FormTextArea/FormTextArea";
import axios from "axios";
import SimpleForm from "../SimpleForm/SimpleForm";
import {useTranslation} from "react-i18next";
import {REST_API_ENDPOINT} from "../../const";
import {UserContext} from "../../contexts/UserContext";

const schema = yup.object({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.number().required(),
    details: yup.string().min(10,'detailsMin').max(500,'detailsMax').required(),
}).required();


export default function ContactForm() {
    const authUser = useContext(UserContext)

    const defaultValues = {
        firstName: authUser?.FamilyName? authUser.FamilyName : "",
        lastName: authUser?.GivenName? authUser.GivenName : "",
        email: authUser?.Email? authUser.Email : "",
        phone: authUser?.PhoneNumber? authUser.PhoneNumber : "",
        details : ""
    };

    const translationNameSpace = "contactForm";
    const {t} = useTranslation();

    const getLabel = (name) => "forms."+translationNameSpace+".labels."+name;

    async function submitPromise(data) {
        return axios.post(REST_API_ENDPOINT+`/email`,  data )
    }

    return (
        <SimpleForm schema={schema} defaultValues={defaultValues} submitPromise={submitPromise} translationNameSpace={translationNameSpace}>
            <FormInput name={"firstName"} label={t(getLabel("firstName"))}/>
            <FormInput name={"lastName"} label={t(getLabel("lastName"))}/>
            <FormInput name={"email"} label={t(getLabel("email"))}/>
            <FormInput name={"phone"} label={t(getLabel("phone"))}/>
            <FormTextArea name={"details"} label={t(getLabel("details"))}/>
        </SimpleForm>
    );
};
