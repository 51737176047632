import React, {useState} from 'react'
import {DialogActions} from "@mui/material";
import Button from "@mui/material/Button";
import AntiqueDialog from "../../../components/AntiqueDialog/AntiqueDialog";
import {useTranslation} from "react-i18next";
import useDelete from "./useDelete";

const Delete = ({product, open, onClose, onDeleted}) => {



    const {t} = useTranslation();

    const [state, setState] = useState({deleted:false})

    const {deleteProduct, deleteLoading, deleteError} = useDelete()

    const handleDelete = () => {
        if(product) {
            deleteProduct(product).then(()=>{
            }).then(()=>setState({deleted:true}))
        }

    }

    const handleClose = () => {
        onClose()
    }

    const handleDeleted = () => {
        onDeleted()
    }

    if(open !== true || product === undefined) return

    return (
        <AntiqueDialog
            title={t('shop.card.deleteTitle')}
            open={open}
            handleClose={handleClose}
            loading={deleteLoading}
            errors={deleteError ? deleteError.graphQLErrors  : undefined}
            description={state.deleted && !deleteError && !deleteLoading ?t('shop.card.deleteSuccessfully',{id:product.id}) : t('shop.card.deleteDescription',{id:product.id})}
        >


            {

                state.deleted && !deleteError && !deleteLoading ?
                    <DialogActions>
                        <Button onClick={handleDeleted}>{t('shop.close')}</Button>
                    </DialogActions>
                    :

                    <DialogActions>
                        <Button onClick={handleClose}>{t('shop.cancel')}</Button>
                        <Button onClick={handleDelete} autoFocus>{t('shop.delete')}</Button>
                    </DialogActions>

            }


        </AntiqueDialog>
    )
}

export default Delete