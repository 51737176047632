import React from "react";
import {AntiqueAppBar} from "../../components/AntiqueAppBar/AntiqueAppBar"
import AntiqueAppFooter from "../../components/AntiqueAppFooter/AntiqueAppFooter";
import {useTranslation} from "react-i18next";
import AntiqueSignIn from "../../components/AntiqueSignIn/AntiqueSignIn";
import {Box, Card, Grid} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import {AppRouteTitle, S3_IMAGE_URL} from "../../const";
import {Route, Routes} from "react-router-dom";
import NoMatch from "../../components/AntiqueProductForm/NoMatch/NoMatch";
import ResetPasswordForm from "../../components/ResetPasswordForm/ResetPasswordForm";
import NewPasswordForm from "../../components/NewPasswordForm/NewPasswordForm";
import {Helmet, HelmetProvider} from "react-helmet-async";

const SignIn = () => {

    const {t} = useTranslation();
    const imgSrc = S3_IMAGE_URL + "index.jpg"



    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>{t(AppRouteTitle.SignIn)}</title>
                    <meta name="description" content={t('signUp.description')} />
                    <meta name="keywords" content={t('signUp.keywords')} />
                </Helmet>
            </HelmetProvider>
            <AntiqueAppBar/>
            <div style={{
                width: '100%',
            }}>
                <Grid container>
                    <Grid item xs={12} lg={2}/>
                    <Grid item xs={12} lg={8}>
                        <Card sx={{ maxWidth: '100%', m:1 }}>

                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={imgSrc}
                                    alt={t('title.signIn')}
                                />
                                <CardContent>
                                <Box sx={{p: 0}}>
                                    <Routes>

                                        <Route path={t('routes.newpassword')} element={<NewPasswordForm/>} />
                                        <Route path={t('routes.resetpassword')} element={<ResetPasswordForm/>} />
                                        <Route path="" element={<AntiqueSignIn/>} />

                                        <Route path="*" element={<NoMatch mess={"from form2"} />}/>

                                    </Routes>

                                </Box>
                                </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <AntiqueAppFooter/>
        </div>
    )
}

export default SignIn;