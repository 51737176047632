import React, {useContext} from "react";
import {Box, Dialog, DialogTitle, IconButton} from "@mui/material";
import {useTranslation} from "react-i18next";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import {Grid} from "@mui/material";
import BuyForm from "../BuyForm/BuyForm";
import ItemImgGallery from "../ItemImgGallery/ItemImgGallery";
import CloseIcon from '@mui/icons-material/Close';
import {UserContext} from "../../contexts/UserContext";

const ShopItemForm = (props) => {

    const {id, price, currency, open, handleClose, images, descriptions, userId} = props;

    const {t, i18n} = useTranslation();
    const authUser = useContext(UserContext)

    if(typeof id === 'undefined') return '';


    const tname = descriptions.find((e)=>{
        return e.language === i18n.resolvedLanguage.toUpperCase();

    })?.title
    const tdesc = descriptions.find((e)=>{
        return e.language === i18n.resolvedLanguage.toUpperCase();

    })?.longDesc



    return (
        typeof id === 'undefined' ? '' :
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{t(tname)}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box sx={{p:2}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={authUser?.UserId === userId ? 12 : 6}>
                    <Card sx={{ maxWidth: 500 }} >
                        <ItemImgGallery images={images} id={id}/>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {tname}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {tdesc}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                {price} {currency}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {
                    authUser?.UserId === userId ? '' :
                        <Grid item xs={12} md={6}>
                            <BuyForm id={id} name={tname} price={price} userId={userId} currency={currency}/>
                        </Grid>
                }

            </Grid>
            </Box>
        </Dialog>
    );
};

export default ShopItemForm;