import * as React from 'react';
import {Link, useLocation} from "react-router-dom";
import {AppRoute, AppRouteTitle} from "../../const";
import {useTranslation} from "react-i18next";
import {LanguageSwitcher} from "../LanguageSwitcher/LanguageSwitcher";
import {useState} from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {S3_IMAGE_URL} from "../../const";
import AntiqueUserMenu from "../AntiqueUserMenu/AntiqueUserMenu";

export const AntiqueAppBar = () => {

    const {t, i18n} = useTranslation();

    const {pathname} = useLocation();


    const getMatchingRoute = () => {
        const parts = pathname.split('/');

        const currentLanguage = i18n.resolvedLanguage;


        for (let i = 2; i < parts.length; i++) {
            if (parts[i].length === 0) continue;
            let routeKey = Object.keys(
                i18n.options.resources[currentLanguage].translation.routes).find(
                key => i18n.options.resources[currentLanguage].translation.routes[key].toLowerCase().includes(parts[i].toLowerCase())
            );
            return i18n.options.resources[currentLanguage].translation.title[routeKey];
        }
    }

    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const returnLink = (url) => {
        return `/${i18n.resolvedLanguage}` + t(url);
    }

    const getLogoSrc = () => {
        let lang = i18n.resolvedLanguage
        return S3_IMAGE_URL + "logo_" + lang + "_small.png"
    }

    return (
        <div>
            <AppBar position={"fixed"} style={{background: '#030502'}}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Box sx={{flexGrow: 1, display: {xs: 'flex', sm: 'none'}}}>
                            <IconButton
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'}
                                }}
                            >
                                <Link to={returnLink(AppRoute.Index)} sx={{my: 2, color: 'white', display: 'block'}}>
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        {t(AppRouteTitle.Index)}
                                    </MenuItem>
                                </Link>
                                <Link to={returnLink(AppRoute.AboutUs)} sx={{my: 2, color: 'white', display: 'block'}}>
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        {t(AppRouteTitle.AboutUs)}
                                    </MenuItem>
                                </Link>
                                <Link to={returnLink(AppRoute.Discover)} sx={{my: 2, color: 'white', display: 'block'}}>
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        {t(AppRouteTitle.Discover)}
                                    </MenuItem>
                                </Link>
                                <Link to={returnLink(AppRoute.GetAQuote)}
                                      sx={{my: 2, color: 'white', display: 'block'}}>
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        {t(AppRouteTitle.GetAQuote)}
                                    </MenuItem>
                                </Link>
                                <Link to={returnLink(AppRoute.AntiqueShop)}
                                      sx={{my: 2, color: 'white', display: 'block'}}>
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        {t(AppRouteTitle.AntiqueShop)}
                                    </MenuItem>
                                </Link>
                                <AntiqueUserMenu/>
                                <MenuItem onClick={handleCloseNavMenu} sx={{color: 'white'}}>
                                    <LanguageSwitcher color={"black"}/>
                                </MenuItem>
                            </Menu>
                            <img
                                src={getLogoSrc()}
                                alt="Logo"
                                height="50"
                            />
                            <Button sx={{my: 0, color: 'white', display: 'block'}}>
                                {getMatchingRoute()}
                            </Button>
                        </Box>

                        <Box sx={{flexGrow: 1, display: {xs: 'none', sm: 'flex'}}}>
                            <Box sx={{display:'flex',width: '70%'}}>
                                <Link to={returnLink(AppRoute.Index)}>
                                    <Button sx={{my: 0, color: 'white', display: 'block'}}>
                                        <img
                                            src={getLogoSrc()}
                                            alt="Logo"
                                            height="50"
                                        />
                                    </Button>
                                </Link>
                                <Link to={returnLink(AppRoute.AboutUs)}>
                                    <Button sx={{my: 2, color: 'white', display: 'block'}}>
                                        {t(AppRouteTitle.AboutUs)}
                                    </Button>
                                </Link>
                                <Link to={returnLink(AppRoute.Discover)}>
                                    <Button sx={{my: 2, color: 'white', display: 'block'}}>
                                        {t(AppRouteTitle.Discover)}
                                    </Button>
                                </Link>
                                <Link to={returnLink(AppRoute.GetAQuote)}>
                                    <Button sx={{my: 2, color: 'white', display: 'block'}}>
                                        {t(AppRouteTitle.GetAQuote)}
                                    </Button>
                                </Link>

                                <Link to={returnLink(AppRoute.AntiqueShop)}>
                                    <Button sx={{my: 2, color: 'white', display: 'block'}}>
                                        {t(AppRouteTitle.AntiqueShop)}
                                    </Button>
                                </Link>
                            </Box>
                            <AntiqueUserMenu/>
                            <Box sx={{flex: 1, display: 'flex', color: 'white', justifyContent: 'flex-end', width:'15%'}}>
                                <LanguageSwitcher color={"white"}/>
                            </Box>
                        </Box>

                    </Toolbar>
                </Container>
            </AppBar>
            <Toolbar/>
        </div>
    );
}
