import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {Card} from "@mui/material";
const AntiqueCard = ({icon, title, onClick}) => {

    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <IconButton onClick={onClick}>
                {
                    icon
                }
                </IconButton>
            </CardContent>
        </Card>
    )
}

export default AntiqueCard