import React, {useContext, useState} from 'react'
import {Avatar, Box, Button, CssBaseline} from "@mui/material";
import Container from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import {useTranslation} from "react-i18next";
import {FormInput} from "../FormInput/FormInput";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {AppRoute} from "../../const";
import {getAttributes, getSession, isContributor, signInWith} from "../../services/cognito";
import AntiqueBackdrop from "../AntiqueBackdrop/AntiqueBackdrop";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {useNavigate} from "react-router-dom";
import {UserDispatchContext} from "../../contexts/UserContext";
import {FormCheckbox} from "../FormCheckBox/FormCheckBox";



const SignInForm = () => {

    const [t] = useTranslation();

    const schema = yup.object({
        email: yup.string().required('forms.signInForm.errors.userNameRequired').email('forms.signInForm.errors.userName'),
        password: yup.string().required('forms.signInForm.errors.password').min(8,'forms.signInForm.errors.passwordMin').max(50,'forms.signInForm.errors.passwordMax').matches(/[A-Z]/, 'forms.signUpForm.errors.passwordUppercase').matches(/[0-9]/, 'forms.signUpForm.errors.passwordNumeric').matches(/[!@#$%^&*]/, 'forms.signUpForm.errors.passwordSymbol'),
        remember: yup.boolean().required().oneOf([true, false],'Message'),
    }).required();




    const [signInErrors, setSignInErrors] = useState([])
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();
    const dispatch = useContext(UserDispatchContext);

    const getLabel = (label) => {
        return t('forms.signInForm.labels.'+label)
    }

    const { handleSubmit, control, formState:{ errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {email:'',password:'',remember:false},
    });

    const onSubmit = (data) => {
        setLoading(true)

        const authObject = {
            JwtToken : '',
            Expiration : 0,
            FamilyName : '',
            GivenName : '',
            PhoneNumber : '',
            Email : data.email,
            UserId : '',
            Newsletter : 0,
            RememberExpiration : 0
        }
        if(data.remember) {
            localStorage.setItem('AntiqueAppUserRemember','true')
            authObject.RememberExpiration = Date.now() + 3600
        }
        signInWith(data.email, data.password).then(()=>{

            return getSession().then((session)=>{
                authObject.JwtToken = session.tokens.idToken.toString()
                authObject.Expiration  = session.tokens.idToken.payload.exp
                try{
                    return getAttributes().then((attributes)=>{
                        authObject.FamilyName =  attributes.family_name
                        authObject.GivenName =  attributes.given_name
                        authObject.PhoneNumber =  attributes["custom:phoneNumber"]
                        authObject.Newsletter =  parseInt(attributes["custom:newsletter"],10)
                        authObject.UserId =  attributes.sub

                        return authObject
                    })
                }
                catch (e) {
                    throw e
                }
            })
        }).then((authObject)=>{
                try{
                    return isContributor().then((res)=>{
                        authObject.isContributor = res
                        return authObject
                    })
                }
                catch (e) {
                    throw e
                }
        }).then((authObject)=>{
            dispatch({
                type: 'signIn',
                response:authObject
            })


        }).catch((e)=>{
            setSignInErrors([e.toString()])
            setLoading(false)
        })
    }

    const signUpLink = () => {
        navigate("./.."+t(AppRoute.SignUp));
    }

    const resetPasswordLink = () => {
        navigate("./"+t('routes.resetpassword'));
    }


    return (

        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <AntiqueBackdrop loading={loading} color={"inherit"} text={t('sendingForm')}/>
            {signInErrors.length ?
                (
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        <ul>
                            {signInErrors.map((error, idx) => {
                                return <li key={idx}>{error}</li>
                            })}
                        </ul>
                    </Alert>
                ) :
                ''
            }
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t('forms.signInForm.labels.signIn')}
                    </Typography>

                    <Box>
                        <FormInput name={"email"} control={control} errors={errors} label={getLabel("emailAddress")} InputProps={{
                            margin:"normal",
                            required:true,
                            fullWidth:true,
                            id:"email",
                            autoComplete: "email",
                            autoFocus:true,
                            disabled:loading,
                            helperText : errors?.email?.message ? t(errors.email.message) : ''
                        }}/>
                        <FormInput name={"password"} control={control} errors={errors} label={getLabel("password")} InputProps={{
                            margin:"normal",
                            required:true,
                            fullWidth:true,
                            type:"password",
                            id:"password",
                            autoComplete:"password",
                            disabled:loading,
                            helperText : errors?.password?.message? t(errors.password.message) : ''
                        }}/>
                        <FormCheckbox name={"remember"} InputProps={{disabled:loading}} control={control} label={getLabel("rememberMe")} errors={errors}/>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {t('forms.signInForm.labels.signIn')}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link component="button" onClick={(e)=>{e.preventDefault(); resetPasswordLink()}} variant="body2">
                                    {t('forms.signInForm.labels.forgotPassword')}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link component="button" onClick={(e)=>{e.preventDefault(); signUpLink()}} variant="body2">
                                    {t('forms.signInForm.labels.signUp')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </form>

    );
}

export default SignInForm