export const portofolio = [
    {
        id:1,
        int : {
            ro: {
                title: 'Canapea',
                desc: 'Ultima lucrare reprezinta o canapea recondiționată. Poze înainte și după recondiționare. Click pentru a mări imaginea.',
            },
            en : {
                title : 'Chair',
                desc : 'Restored chairs (before and after). Click on image to display original size.',
            },
            it : {
                title : 'Divano',
                desc : 'L\'ultimo lavoro è un divano ristrutturato. Foto prima e dopo la ristrutturazione. Clicca per allargare l\'immagine.',
            }
        },
        date : '2022-06-01'
    }
]