import React from "react";
import {AntiqueAppBar} from "../../components/AntiqueAppBar/AntiqueAppBar"
import {useTranslation, Trans} from "react-i18next";
import AntiqueAppFooter from "../../components/AntiqueAppFooter/AntiqueAppFooter";
import {Box, Card, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Toolbar from "@mui/material/Toolbar";
import {AppRouteTitle, S3_IMAGE_URL} from "../../const";
import {Helmet, HelmetProvider} from "react-helmet-async";

const Discover = () => {

    const {t} = useTranslation();

    const imgSrc = S3_IMAGE_URL + "discover.jpg"


    return (
        <div><HelmetProvider>
            <Helmet>
                <title>{t(AppRouteTitle.Discover)}</title>
                <meta name="description" content={t('Discover.description')} />
                <meta name="keywords" content={t('Discover.keywords')} />
            </Helmet></HelmetProvider>
            <AntiqueAppBar/>
            <div
                style={{width: '100%'}}>
                <Container>
                    <Card sx={{maxWidth: '100%', m: 1}}>

                        <CardMedia
                            component="img"
                            height="140"
                            image={imgSrc}
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography variant="h5" component="h2">{t('Discover.title')}</Typography>

                            {[...Array(6)].map((e, i) => {

                                return t('Discover.point'+i+'.title').length > 1 ?
                                (
                                    <div key={'Discover.point'+i}>
                                        <Box sx={{p: 1, m: 1}}>
                                            <Typography variant="h3" gutterBottom>{t('Discover.point'+i+'.title')}</Typography>
                                            <Typography variant="h6" gutterBottom>
                                                <ul>

                                                    {[...Array(2)].map((e2, j) => {
                                                        const key = 'Discover.point'+i+'.text'+j
                                                        return t(key).length > 1 ? (
                                                            <li key={key}><Trans i18nKey={key} /></li>
                                                        ) : ''
                                                    })}

                                                </ul>

                                            </Typography>
                                        </Box>
                                        <Toolbar  />
                                    </div>

                                ) :
                                ''
                            })
                            }
                        </CardContent>
                    </Card>


                </Container>

            </div>

            <AntiqueAppFooter/>
        </div>
    )
}

export default Discover;