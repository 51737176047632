export const AppRoute = {
    Index : 'routes.index',
    AboutUs : 'routes.aboutus',
    AntiqueShop : 'routes.antiqueshop',
    GetAQuote : 'routes.getaquote',
    MyPlace : 'routes.myplace',
    SignIn : 'routes.signin',
    SignUp : 'routes.signup',
    SignOut : 'routes.logout',
    MyProfile : 'routes.myprofile',
    Forbidden : 'routes.forbidden',
    Discover : 'routes.discover',
    Privacy : 'routes.privacy',
    NotFound : '*',

}

export const AppRouteTitle = {
    Index : 'title.index',
    AboutUs : 'title.aboutus',
    AntiqueShop : 'title.antiqueshop',
    GetAQuote : 'title.getaquote',
    MyPlace : 'title.myplace',
    SignIn : 'title.signin',
    SignOut : 'title.logout',
    MyProfile : 'title.myprofile',
    Forbidden : 'title.forbidden',
    Discover : 'title.discover',
    Privacy : 'title.privacy',
}

export const FormSteps = {
    Step1 : 'routes.getaquoteStep1',
    Step2 : 'routes.getaquoteStep2',
    Step3 : 'routes.getaquoteStep3',
    Step4 : 'routes.getaquoteStep4',
}

export const FormStepsTitle = {
    Step1 : 'steps.title.0',
    Step2 : 'steps.title.1',
    Step3 : 'steps.title.2',
    Step4 : 'steps.title.3',
}

export const FormStepsDescription = {
    0 : 'steps.description.0',
    1 : 'steps.description.1',
    2 : 'steps.description.2',
    3 : 'steps.description.3',
}

export const Product = {
    create : 'routes.create',
    edit : 'routes.edit',
    list : 'routes.list'
}





export const AddFormSteps = {
    Step1 : 'routes.addProductStep1',
    Step2 : 'routes.addProductStep2',
    Step3 : 'routes.addProductStep3',
    Step4 : 'routes.addProductStep4',
    Step5 : 'routes.addProductStep5',
}

export const AddFormStepsTitle = {
    Step1 : 'forms.addForm.steps.title.0',
    Step2 : 'forms.addForm.steps.title.1',
    Step3 : 'forms.addForm.steps.title.2',
    Step4 : 'forms.addForm.steps.title.3',
    Step5 : 'forms.addForm.steps.title.4',
}

export const AddFormStepsDescription = {
    0 : 'forms.addForm.steps.description.0',
    1 : 'forms.addForm.steps.description.1',
    2 : 'forms.addForm.steps.description.2',
    3 : 'forms.addForm.steps.description.3',
    4 : 'forms.addForm.steps.description.4',
}