import React from "react";
import { Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';

export function FormTextArea (props)
{

    const {  name, control, label, errors, InputProps } = props;

    return (
        <Controller
            multiline
            name={name}
            control={control}
            render={({field: {onChange, value, name}}) => (
                <TextField {...InputProps} onChange={onChange} value={value} label={label} name={name} error={errors?.hasOwnProperty(name)}/>
            )}
        />
    );
}