import React, {useEffect, useState} from "react";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Box, Card, CircularProgress} from "@mui/material";
import AntiqueProductForm from "../../../components/AntiqueProductForm/AntiqueProductForm";
import {useTranslation} from "react-i18next";
import {S3_IMAGE_URL} from "../../../const";
import {useStateMachine} from "little-state-machine";
import clearAction from "../../../components/AntiqueProductForm/ClearAction";

const Create = () => {

    const {t} = useTranslation();
    const imgSrc = S3_IMAGE_URL + "index.jpg"
    const {actions} = useStateMachine({clearAction});

    const [loaded, setLoaded] = useState(false)

    useEffect(() => {


        if (!loaded) {

            actions.clearAction()
            setLoaded(true)
        }
    }, [loaded, actions]);
    return (
        <Card sx={{maxWidth: '100%', m: 1}}>

            <CardMedia
                component="img"
                height="140"
                image={imgSrc}
                alt={t('title.myplace')}
            />
            <CardContent>
                <Typography variant="h5" component="h2">{t('title.createProduct')}</Typography>
                {
                    !loaded ? <CircularProgress/> :
                        <Box sx={{p: 0}}>
                            <AntiqueProductForm/>
                        </Box>
                }
            </CardContent>
        </Card>
    )

}

export default Create