import React from 'react'
import ContactForm from '../../components/ContactForm/ContactForm'
import Container from "@mui/material/Container";
import {Card, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import {useTranslation} from "react-i18next";

export const ContactView = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item md={4} xs={12} sm={6} lg={3} key={1}>
                    <ContactForm/>
                </Grid>
                <Grid item md={4} xs={12} sm={6} lg={3} key={2}>
                    <Card>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {t('ContactView.title')}
                            </Typography>
                            <Typography variant="body2">
                                {t('ContactView.description')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </Container>
    )
}