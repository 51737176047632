import React from "react";
import { useForm } from "react-hook-form";
import {FormInput} from "../../FormInput/FormInput";
import Button from "@mui/material/Button";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AddFormSteps} from "../../../const";
import {Grid} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencySelect from "../../CurrencySelect/CurrencySelect";
import {Currencies} from "../../../const/currencies";

const schema = yup.object({
    name: yup.string().required(),
    price: yup.number().required(),
    currency: yup.mixed().oneOf(Currencies).required(),
}).required();

const Step1 = ({formData, onUpdate}) => {

    const navigate = useNavigate();

    const {t} = useTranslation();

    const defaultValues = {
        name: "",
        price: "",
        currency: ""
    }

    const populateDefaultValues = () =>{
        const newValues = {...defaultValues};
        Object.keys(newValues).map((key) => {
            if(typeof formData[key] !== 'undefined') {
                newValues[key] = formData[key];
            }
            return true;
        })
        return newValues;
    }

    const { handleSubmit, control, formState:{ errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: populateDefaultValues()
    });

    const onReset = () => {
        reset(defaultValues)
    }

    const onSubmit = data => {
        onUpdate(data).then(()=>{
            navigate("./"+t(AddFormSteps.Step2));
        })
    };

    const getFormLabelTranslationFor = (name) => t('forms.addForm.labels.'+name);
    const getFormErrorTranslationFor = (name) => t('forms.addForm.errors.'+name);

    return (



            <form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
                <Grid container spacing={2} margin={2}>
                <Grid item xs={12}>
                    <FormInput name={"name"}
                               control={control}
                               label={t(getFormLabelTranslationFor("name"))}
                               errors={errors}
                               InputProps = {{
                                   helperText : errors?.name?.message ? t(getFormErrorTranslationFor("name")) : ''
                               }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInput name={"price"}
                               control={control}
                               label={t(getFormLabelTranslationFor("price"))}
                               errors={errors}
                               InputProps = {{
                                   helperText : errors?.price?.message ? t(getFormErrorTranslationFor("price")) : ''
                               }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CurrencySelect name={"currency"}
                                    control={control}
                                    label={t(getFormLabelTranslationFor("currency"))}
                                    errors={errors}
                                    InputProps = {{
                                    helperText : errors?.currency?.message ? t(getFormErrorTranslationFor("currency")) : ''
                                    }}

                    />
                </Grid>
                <Grid item xs={12}>
                    <Button startIcon={<DeleteIcon />} onClick={onReset} variant={"outlined"}>
                        {t('reset')}
                    </Button>
                    <Button startIcon={<ArrowForwardIcon />} onClick={handleSubmit(onSubmit)} variant={"contained"}>
                        {t('next')}
                    </Button>
                </Grid>
                </Grid>
            </form>
    );
};

export default Step1;