import React from "react";
import {AntiqueAppBar} from "../../components/AntiqueAppBar/AntiqueAppBar";
import AntiqueAppFooter from "../../components/AntiqueAppFooter/AntiqueAppFooter";
import AntiqueUserForm from "../../components/AntiqueUserForm/AntiqueUserForm";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import {Card, Container} from "@mui/material";
import {AppRouteTitle, S3_IMAGE_URL} from "../../const";
import {useTranslation} from "react-i18next";
import {Helmet, HelmetProvider} from "react-helmet-async";


const MyProfile = () => {

    const imgSrc = S3_IMAGE_URL + "index.jpg"
    const {t} = useTranslation();

    return (
        <div>
            <HelmetProvider><Helmet>
                <title>{t(AppRouteTitle.MyProfile)}</title>
            </Helmet></HelmetProvider>
            <AntiqueAppBar/>
            <div style={{
                width: '100%',
            }}>
                <Container>
                    <Card sx={{ maxWidth: '100%', m:1 }}>

                        <CardMedia
                            component="img"
                            height="140"
                            image={imgSrc}
                            alt={t('title.getaquote')}
                        />
                        <CardContent>
                            <AntiqueUserForm/>
                        </CardContent>
                    </Card>
                </Container>
            </div>
            <AntiqueAppFooter/>
        </div>
    )
}

export default MyProfile;