import React, {useContext} from 'react'
import {UserContext} from "../../contexts/UserContext";
import {useLocation, Navigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AppRoute} from "../../const";

const AntiqueAnonymousRoute = ({children}) => {

    const userAuth = useContext(UserContext)
    const location = useLocation();

    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;

    const getRedirect = () => {
        return "/"+currentLanguage+t(AppRoute.MyProfile);
    }

    if (userAuth && userAuth.Expiration && userAuth.Expiration*1000 > Date.now()) {
        return <Navigate to={getRedirect()} replace state={{ from: location }} />;
    }

    return children

}

export default AntiqueAnonymousRoute