import React from "react";
import {AntiqueAppBar} from "../../components/AntiqueAppBar/AntiqueAppBar"
import {useTranslation} from "react-i18next";
import AntiqueAppFooter from "../../components/AntiqueAppFooter/AntiqueAppFooter";
import {Card, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import {AppRouteTitle, S3_IMAGE_URL} from "../../const";
import {Helmet, HelmetProvider} from "react-helmet-async";
function GetPolicy(props) {

    const {lang} = props

    if(lang === 'en') {
        return (
            <>
                <br/>
                This Privacy Policy outlines how eliseiantiques.com collects, uses, and protects your personal
                information.
                <br/><br/>
                <Typography variant="h5" component="h2">Information We Collect</Typography>
                <br/>
                We may collect the following types of personal information:
                <br/>
                Personal Information: Name, email address, phone number, and other contact information.<br/>
                Usage Data: Information about how you interact with our website or application, such as pages visited,
                time spent, and actions taken.<br/>
                Device Information: Information about your device, such as your IP address, browser type, and operating
                system.<br/>
                <br/>
                <Typography variant="h5" component="h2">How We Use Your Information</Typography>
                <br/>
                We may use your personal information for the following purposes:
                <br/>
                To provide and improve our services. <br/>
                To communicate with you, such as sending you notifications or marketing emails. <br/>
                To analyze usage patterns and trends. <br/>
                To protect our website and users from security threats. <br/>
                <br/>
                <Typography variant="h5" component="h2">Data Sharing</Typography>
                <br/>
                We do not share your personal information with any third-party companies or organizations. We are
                committed to protecting your privacy and will only use your information for the purposes stated in this
                policy. <br/>
                <br/>
                <Typography variant="h5" component="h2">Data Security</Typography>
                <br/>
                We implement reasonable security measures to protect your personal information from unauthorized access,
                use, disclosure, alteration, or destruction. However, please note that no security measure is perfect,
                and we cannot guarantee the absolute security of your information. <br/>
                <br/>
                <Typography variant="h5" component="h2" id="delete">Your Rights <a href="#delete" aria-hidden="true">#</a></Typography>
                <br/>
                You may have certain rights regarding your personal information, such as the right to access, correct,
                or delete your information.<br/>
                <b>You can delete your account and all the data associated with your account by using the "Remove account" button from your profile page.</b>
                <br/>
                    <br/>
                <Typography variant="h5" component="h2">Changes to This Privacy Policy</Typography>
                <br/>
                We may update this Privacy Policy from time to time. We will notify you of any material changes by
                posting the new Privacy Policy on our website. <br/>
                <br/>
                <Typography variant="h5" component="h2">Contact Us</Typography>
                <br/>
                If you have any questions or concerns about this Privacy Policy, please contact us at [contact@anticariatelisei.ro]
            </>
        )
    }
    else if(lang === 'ro'){
        return (
            <>
                <br/>
                This Privacy Policy outlines how eliseiantiques.com collects, uses, and protects your personal
                information.
                <br/><br/>
                <Typography variant="h5" component="h2">Information We Collect</Typography>
                <br/>
                We may collect the following types of personal information:
                <br/>
                Personal Information: Name, email address, phone number, and other contact information.<br/>
                Usage Data: Information about how you interact with our website or application, such as pages visited,
                time spent, and actions taken.<br/>
                Device Information: Information about your device, such as your IP address, browser type, and operating
                system.<br/>
                <br/>
                <Typography variant="h5" component="h2">How We Use Your Information</Typography>
                <br/>
                We may use your personal information for the following purposes:
                <br/>
                To provide and improve our services. <br/>
                To communicate with you, such as sending you notifications or marketing emails. <br/>
                To analyze usage patterns and trends. <br/>
                To protect our website and users from security threats. <br/>
                <br/>
                <Typography variant="h5" component="h2">Data Sharing</Typography>
                <br/>
                We do not share your personal information with any third-party companies or organizations. We are
                committed to protecting your privacy and will only use your information for the purposes stated in this
                policy. <br/>
                <br/>
                <Typography variant="h5" component="h2">Data Security</Typography>
                <br/>
                We implement reasonable security measures to protect your personal information from unauthorized access,
                use, disclosure, alteration, or destruction. However, please note that no security measure is perfect,
                and we cannot guarantee the absolute security of your information. <br/>
                <br/>
                <Typography variant="h5" component="h2" id="delete">Your Rights <a href="#delete" aria-hidden="true">#</a></Typography>
                <br/>
                You may have certain rights regarding your personal information, such as the right to access, correct,
                or delete your information.<br/>
                <b>You can delete your account and all the data associated with your account by using the "Remove account" button from your profile page</b>
                <br/>
                <Typography variant="h5" component="h2">Changes to This Privacy Policy</Typography>
                <br/>
                We may update this Privacy Policy from time to time. We will notify you of any material changes by
                posting the new Privacy Policy on our website. <br/>
                <br/>
                <Typography variant="h5" component="h2">Contact Us</Typography>
                <br/>
                If you have any questions or concerns about this Privacy Policy, please contact us at [contact@anticariatelisei.ro]
            </>
        )
    }
    else if(lang === 'it') {
        return (
            <>
                <br/>
                This Privacy Policy outlines how anticariatelisei.ro collects, uses, and protects your personal
                information.
                <br/><br/>
                <Typography variant="h5" component="h2">Information We Collect</Typography>
                <br/>
                We may collect the following types of personal information:
                <br/>
                Personal Information: Name, email address, phone number, and other contact information.<br/>
                Usage Data: Information about how you interact with our website or application, such as pages visited,
                time spent, and actions taken.<br/>
                Device Information: Information about your device, such as your IP address, browser type, and operating
                system.<br/>
                <br/>
                <Typography variant="h5" component="h2">How We Use Your Information</Typography>
                <br/>
                We may use your personal information for the following purposes:
                <br/>
                To provide and improve our services. <br/>
                To communicate with you, such as sending you notifications or marketing emails. <br/>
                To analyze usage patterns and trends. <br/>
                To protect our website and users from security threats. <br/>
                <br/>
                <Typography variant="h5" component="h2">Data Sharing</Typography>
                <br/>
                We do not share your personal information with any third-party companies or organizations. We are
                committed to protecting your privacy and will only use your information for the purposes stated in this
                policy. <br/>
                <br/>
                <Typography variant="h5" component="h2">Data Security</Typography>
                <br/>
                We implement reasonable security measures to protect your personal information from unauthorized access,
                use, disclosure, alteration, or destruction. However, please note that no security measure is perfect,
                and we cannot guarantee the absolute security of your information. <br/>
                <br/>
                <Typography variant="h5" component="h2">Your Rights</Typography>
                <br/>
                You may have certain rights regarding your personal information, such as the right to access, correct,
                or delete your information. Please contact us to exercise these rights. <br/>
                <br/>
                <Typography variant="h5" component="h2">Changes to This Privacy Policy</Typography>
                <br/>
                We may update this Privacy Policy from time to time. We will notify you of any material changes by
                posting the new Privacy Policy on our website. <br/>
                <br/>
                <Typography variant="h5" component="h2">Contact Us</Typography>
                <br/>
                If you have any questions or concerns about this Privacy Policy, please contact us at [contact@anticariatelisei.ro]
            </>
        )
    }

}

const Privacy = () => {

    const {t, i18n} = useTranslation();

    const imgSrc = S3_IMAGE_URL + "aboutus.jpg"



    return (
        <div>
                <HelmetProvider><Helmet>
                <title>{t(AppRouteTitle.Privacy)}</title>
                <meta name="description" content={t('privacy.description')} />
                <meta name="keywords" content={t('privacy.keywords')} />
            </Helmet></HelmetProvider>
            <AntiqueAppBar/>
            <div
                style={{width: '100%'}}>
                <Container>
                    <Card sx={{maxWidth: '100%', m: 1}}>

                        <CardMedia
                            component="img"
                            height="140"
                            image={imgSrc}
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography variant="h5" component="h2">{t('title.privacy')}</Typography>

                            <GetPolicy lang={i18n.language}/>

                        </CardContent>
                    </Card>


                </Container>

            </div>

            <AntiqueAppFooter/>
        </div>
    )
}

export default Privacy;