import React from 'react';
import { Controller } from "react-hook-form";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import {FormHelperText} from "@mui/material";

const FormSelect = ({
                    name,
                    label,
                    control,
                    value,
                    children,
                    errors,
                    InputProps
                   }) => {



    return (
        <Controller name={name}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} error={!!errors[name]}>
                        <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
                            <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                                value={value}
                                label={label}
                                onChange={onChange}
                            >
                                {children}
                            </Select>
                            <FormHelperText error={!!errors[name]}>{InputProps?.helperText?InputProps.helperText:''}</FormHelperText>
                        </FormControl>
                    )}
        />
    );
};

export default FormSelect;