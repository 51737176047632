import React from "react"
import {Box} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {useTheme} from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {ColorModeContext} from "../AntiqueTheme/AntiqueTheme";


const AntiqueDarkButton = (color) => {

    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color:color,
            }}
        >
            {theme.palette.mode} mode
            <IconButton sx={{ml: 1}} onClick={colorMode.toggleColorMode} color="inherit">
                {theme.palette.mode === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/>}
            </IconButton>
        </Box>
    )
}

export default AntiqueDarkButton