import React from "react"
import {Box, Button, Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {useTranslation} from "react-i18next";
import {S3_IMAGE_URL} from "../../const";

const AntiquePortofolioCard = ({id, title, description, date}) => {

    const {t} = useTranslation();

    const getImgSrc = (type) => {
        return S3_IMAGE_URL + 'portofolio/' + type + '_' + id + '.jpg';
    }

    const openImage = (type, event) => {
        window.open(getImgSrc(type))
        event.preventDefault()
    }

    return (
        <Card>
            <CardMedia>
                <Grid container height={200}>
                    <Grid item md={6} xs={12}>
                        <div style={{float: 'left', position: 'absolute', margin:5}}>
                            <IconButton>
                                <OpenInNewIcon/>
                            </IconButton>
                        </div>
                        <Box sx={{
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${getImgSrc('before')})`,
                            backgroundColor: '#7fc7d9', // Average color of the background image.
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <Button tooltip={t('shop.openInFull')} onClick={(event) => openImage('before', event)} sx={{width: '100%',height: '100%'}}/>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div style={{float: 'left', position: 'absolute', margin:5}}>
                            <IconButton>
                                <OpenInNewIcon/>
                            </IconButton>
                        </div>
                        <Box sx={{
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${getImgSrc('after')})`,
                            backgroundColor: '#7fc7d9', // Average color of the background image.
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <Button tooltip={t('shop.openInFull')} onClick={(event) => openImage('after', event)} sx={{width: '100%',height: '100%'}}/>
                        </Box>

                    </Grid>
                </Grid>
            </CardMedia>
            <CardContent>
                <Typography sx={{ fontSize: 20,paddingBottom:2 }} gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body2">
                    {description} {date}
                </Typography>
            </CardContent>

        </Card>
    )
}

export default AntiquePortofolioCard