import React from "react";
import {AntiqueAppBar} from "../../components/AntiqueAppBar/AntiqueAppBar";
import {Container} from "@mui/material";
import AntiqueAppFooter from "../../components/AntiqueAppFooter/AntiqueAppFooter";
import {useTranslation} from "react-i18next";
import {AppRouteTitle, Product} from "../../const";
import {Route, Routes} from "react-router-dom";
import NoMatch from "../../components/AntiqueProductForm/NoMatch/NoMatch";
import Edit from "./edit/Edit";
import Create from "./create/Create";
import {StateMachineProvider} from "little-state-machine";
import List from "./list/List";
import {Helmet, HelmetProvider} from "react-helmet-async";


const MyPlace = () => {

    const {t} = useTranslation();

    function localizeRoutePath(path) {
        switch (typeof path) {
            case 'undefined':
                return undefined;
            default:
                if (path === '*') return path;
                let path2 = t(path)
                if (path2[path2.length - 1] === '/' && path2.length > 1) path2 = path2 + '*'
                return path2
        }
    }

    return (
        <div>
            <HelmetProvider> <Helmet>
                <title>{t(AppRouteTitle.MyPlace)}</title>
            </Helmet></HelmetProvider>
            <AntiqueAppBar/>
            <div style={{
                width: '100%',
            }}>
                <Container>
                    <StateMachineProvider>
                        <Routes>
                            <Route path={localizeRoutePath(Product.list)} element={<List/>}/>
                            <Route path={localizeRoutePath(Product.edit) + "/:productId/*"} element={<Edit/>}/>
                            <Route path={localizeRoutePath(Product.create)} element={<Create/>}/>
                            <Route path="*" element={<NoMatch mess={"from MyPlace"}/>}/>
                        </Routes>
                    </StateMachineProvider>
                </Container>
            </div>
            <AntiqueAppFooter/>
        </div>
    )
}

export default MyPlace;