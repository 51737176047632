import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Alert, AlertTitle, Typography} from "@mui/material";
import Container from "@mui/material/Grid";
import Grid from "@mui/material/Grid";
import {AntiqueAppBar} from "../../components/AntiqueAppBar/AntiqueAppBar";
import AntiqueAppFooter from "../../components/AntiqueAppFooter/AntiqueAppFooter";
import i18n from "i18next";
import {AppRoute, S3_IMAGE_URL} from "../../const";
import AntiqueErrorCard from "../../components/AntiqueErrorCard/AntiqueErrorCard";


const NoMatch = ({mess}) => {
    let location = useLocation()

    const navigate = useNavigate();

    const {t} = useTranslation()

    const returnLink = (url) => {
        return `/${i18n.resolvedLanguage}` + t(url);
    }

    const navigateToIndex = () => {
        navigate(returnLink(t(AppRoute.Index)))
    }

    const desc = () => {
        return <Alert severity="error">
            <AlertTitle>{t('noMatch.title')}</AlertTitle>
            {mess}{t('noMatch.description')}: <strong>{location.pathname}</strong>
            <Typography>{t('noMatch.click')}</Typography>
        </Alert>
    }

    const getImgSrc = (name) => {
        return S3_IMAGE_URL+ name
    }

    return (
        <div>
            <AntiqueAppBar/>
            <div style={{width: '100%'}}>
                <Container style={{height: '100%'}}>
                    <Container
                        sx={{
                            mt: 3,
                            mb: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                    <Grid container sx={{width:'50%'}}>
                        <Grid item xs={12} md={12}>
                            <AntiqueErrorCard title={t('error')} alt={t('error')} description={desc()} onClick={navigateToIndex} imgSrc={getImgSrc('error.jpg')}/>
                        </Grid>

                    </Grid>
                    </Container>
                </Container>
            </div>
            <AntiqueAppFooter/>
        </div>
    )
}

export default NoMatch