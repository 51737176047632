import {gql, useMutation} from "@apollo/client";
const useDelete = () => {
    const DELETE_IMAGE = gql`
    mutation MyMutation($productId : ID!, $imageId : ID!) {
      removeImageWith(productId: $productId, imageId : $imageId) {
        imageId
      }
    }
    `;
    const DELETE_DESCRIPTION = gql`
    mutation MyMutation($productId : ID!, $descriptionId : ID!) {
      removeDescriptionWith(productId: $productId, descriptionId : $descriptionId) {
        descriptionId
      }
    }
    `;
    const DELETE_PRODUCT = gql`
    mutation MyMutation($productId : ID!) {
      removeProduct(productId: $productId) {
        id
      }
    }
    `;
    const [mutateProduct, { loading:loading1, error:error1 }] = useMutation(DELETE_PRODUCT,{ errorPolicy: "all" });
    const [mutateImages, { loading:loading2, error:error2 }] = useMutation(DELETE_IMAGE,{ errorPolicy: "all" });
    const [mutateDescriptions, { loading:loading3, error:error3 }] = useMutation(DELETE_DESCRIPTION,{ errorPolicy: "all" });


    const deleteImagesAsync = (images, productId) => {
        const promises = []
        images.map((image)=> {
            promises.push(
                new Promise((resolve, reject)=> {
                    try{
                        const ret = mutateImages({variables: {
                                imageId : image.imageId,
                                productId: productId
                            }})
                        resolve(ret)
                    }
                    catch (e) {
                        reject(e)
                    }
                })
            )
            return true
        })
        return Promise.all(promises)

    }

    const deleteDescriptionsAsync = (descriptions, productId) => {
        const promises = []
        descriptions.map((desc)=> {
            promises.push(
                new Promise((resolve, reject)=> {
                    try{
                        const ret = mutateDescriptions({variables: {
                                descriptionId : desc.descriptionId,
                                productId: productId
                            }})
                        resolve(ret)
                    }
                    catch (e) {
                        reject(e)
                    }
                })
            )
            return true
        })
        return Promise.all(promises)

    }

    const deleteProductAsync = (productId) => {
        return mutateProduct({
            variables : {
                productId : productId
            }
        })
    }

    const deleteProduct = (product) => {
        return deleteImagesAsync(product.images, product.id).then(()=>{
            if(error1) return
            return deleteDescriptionsAsync(product.descriptions, product.id).then(()=>{
                if(error2) return
                return deleteProductAsync(product.id).then((r)=>r)
            })
        }).catch(()=>{

        })
    }

    const deleteLoading = loading1 | loading2 | loading3
    const deleteError = error1?error1:error2?error2:error3

    return {deleteProduct, deleteLoading, deleteError}
}
export default useDelete