import React, {useEffect, useState} from "react";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Box, Card, CircularProgress, Grid} from "@mui/material";
import AntiqueProductForm from "../../../components/AntiqueProductForm/AntiqueProductForm";
import {useTranslation} from "react-i18next";
import {S3_IMAGE_URL} from "../../../const";
import {useParams} from "react-router-dom";
import {useStateMachine} from "little-state-machine";
import updateAction from "../../../components/AntiqueProductForm/UpdateAction";
import {gql, useQuery} from "@apollo/client";
import AntiqueErrorCard from "../../../components/AntiqueErrorCard/AntiqueErrorCard";
import Container from "@mui/material/Grid";
import clearAction from "../../../components/AntiqueProductForm/ClearAction";

const Edit = () => {

    const GET_PRODUCT = gql`
  query getProduct($id : ID!) {
  getProduct(id:$id) {
      currency
      descriptions {
        language
        desc
        longDesc
        title
        descriptionId
      }
      id
      name
      price
      status
      images {
        normal
        thumb
        imageId
      }
    }
}

`;

    const {t} = useTranslation();
    const imgSrc = S3_IMAGE_URL + "index.jpg"

    const {productId} = useParams();

    const [loaded, setLoaded] = useState(false)

    const {actions} = useStateMachine({updateAction, clearAction});
    const {loading, error, data} = useQuery(GET_PRODUCT, {
        variables: {
            id: productId
        }
    });

    const getNewInstance = (data) => {

        const obj = {
            id: data.getProduct.id,
            name: data.getProduct.name,
            price: data.getProduct.price,
            currency : data.getProduct.currency,
            descriptions : [],
            images : [],
        };

        data.getProduct.images?.map((image)=>{
            obj.images.push({

                fileData : {
                    size : 0,
                    name : image.normal,
                    type : ''
                },
                content : '',
                loading:false,
                progress:0,
                imageId: image.imageId,
                imageURL : S3_IMAGE_URL+'items/item'+data.getProduct.id+'/'+image.normal
            })
            return true
        })

        data.getProduct.descriptions?.map((description)=>{
            obj.descriptions.push({
                title : description.title,
                desc : description.desc,
                longDesc : description.longDesc,
                language : description.language,
                descriptionId : description.descriptionId
            })
            return true
        })


        return obj;
    }

    useEffect(() => {


        if (!loading && !error && data?.getProduct && productId && !loaded) {

            actions.updateAction(getNewInstance(data))
            setLoaded(true)
        }
    }, [loading, error, data, productId, loaded, actions]);

    const getImgSrc = (name) => {
        return S3_IMAGE_URL+ name
    }

    return (
        <Card sx={{maxWidth: '100%', m: 1}}>

            <CardMedia
                component="img"
                height="140"
                image={imgSrc}
                alt={t('title.myplace')}
            />
            <CardContent>
                <Typography variant="h5" component="h2">{t('title.editProduct')} {productId}</Typography>
                {
                    loading || !loaded ? <CircularProgress/> :
                        error ?
                            <Box sx={{p: 0}}>
                                {error}
                            </Box>
                            :
                            data?.getProduct && productId ?
                                <Box sx={{p: 0}}>
                                    <AntiqueProductForm/>
                                </Box>
                                :
                                <Container
                                    sx={{
                                        mt: 3,
                                        mb: 10,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                <Grid container sx={{width:'50%'}}>
                                    <Grid item xs={12} md={12}>
                                        <AntiqueErrorCard title={"Product not found"} description={"Product id "+productId+" not found"} imgSrc={getImgSrc('error.jpg')}/>
                                    </Grid>
                                </Grid>
                                </Container>

                }
            </CardContent>
        </Card>

    )

}

export default Edit