import React,{ useEffect, useState} from "react";
import {Routes, Route, useLocation, useNavigate} from "react-router-dom";
import {createStore, useStateMachine} from "little-state-machine";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import Step5 from "./Step5/Step5";
import NoMatch from "./NoMatch/NoMatch";
import FormStepper from "../FormStepper/FormStepper";
import {AddFormStepsTitle, AddFormSteps, AddFormStepsDescription} from "../../const";
import {useTranslation} from "react-i18next";
import Container from "@mui/material/Container";
import updateAction from "./UpdateAction";
import AntiqueBackdrop from "../AntiqueBackdrop/AntiqueBackdrop";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {useAntiqueProductForm} from "./useAntiqueProductForm";


createStore(
    {
        name: '',
        price: '',
        currency : '',
        descriptions : [
            /*
            {
                title : '',
                desc : '',
                longDesc : '',
                language : 'EN'
            }
            */
        ],
        images : [],
    });


export default function AntiqueProductForm() {

    const location = useLocation();
    const {t} = useTranslation();

    const activeStep = () =>{
        const path = location.pathname?.substring(location.pathname?.lastIndexOf('/')+1);
        switch (path){
            case t(AddFormSteps.Step5):
                return 4;
            case t(AddFormSteps.Step2):
                return 1;
            case t(AddFormSteps.Step3):
                return 2;
            case t(AddFormSteps.Step4):
                return 3;
            case t(AddFormSteps.Step1):
                return 0;
            default:
                return 0;
        }
    }

    function localizeRoutePath(path) {
        switch (typeof path) {
            case 'undefined':
                return undefined;
            default:
                if(path === '*') return path;
                let path2 = t(path)
                if(path2[path2.length-1] === '/' && path2.length > 1) path2 = path2 + '*'
                return path2
        }
    }

    const { actions, state } = useStateMachine({ updateAction });

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState([]);
    const [errors, setErrors] = useState([]);

    const onUpdate = async (data) => {
        await actions.updateAction(data);
    }

    const {uploadImages, addDescription, addImage, addProduct} = useAntiqueProductForm();

    const addError = (value) => {
        setErrors((prev)=>[...prev, value])
    }
    const setUploadingImage = (index, progress) => {
        state.images[index].loading = true;
        state.images[index].progress = progress;
    }

    const resetImage = (index) => {
        state.images[index].loading = false;
        state.images[index].progress = 0;
    }

    const addStatus = (message) => {
        setStatus((prev)=>[...prev,message]);
    }


    const onSubmitAsync = async () => {
        setErrors([])
        setStatus([])
        setLoading(true);
    }

    const navigate = useNavigate();

    useEffect(()=>{
        if(loading && errors.length === 0 && status.length === 0) {
            startProcessAsync(
                (result)=>{
                    if(result!== undefined) navigate("./"+t(AddFormSteps.Step5), {replace:true, state: {result : result}});
                }
            )
        }
    })

    const startProcessAsync = async (onFinishCallBack) => {



        let start = new Promise((resolve, reject) => {

            try {
                let vars = {
                    currency: state.currency,
                    name: state.name,
                    price: state.price,
                    id: null
                }
                if(state.id !== undefined) vars.id = state.id

                const result = addProduct({
                    variables: vars
                })
                addStatus('Saving product details')
                resolve(result)
            }
            catch (e) {
                reject(e)
            }
        })

        start.then((r)=>{
            let promises = []
            const productId = r.data.addProduct.id
            state.descriptions.map((item)=>{
                return promises.push(new Promise((resolve,reject)=>{
                    try {
                        let vars = {
                            desc: item.desc,
                            language: item.language,
                            longDesc: item.longDesc,
                            productId: productId,
                            title: item.title,
                            descriptionId : null
                        }
                        if(item.descriptionId !== undefined) vars.descriptionId = item.descriptionId
                        const result = addDescription({
                            variables: vars
                        });
                        addStatus('Saving product description in ' + item.language)
                        resolve({result, productId})
                    }
                    catch (e) {
                        reject(e)
                    }
                }))
            })
            return Promise.all(promises);
        }).then((r)=>{
            const { productId } = r[0]
            addStatus('Uploading images')
            const found = state.images.find((item)=>{
                return item.content.length > 0
            })
            if(found) return uploadImages(state.images, setUploadingImage, resetImage, addError, productId)
            return [{productId: productId}]
        }).then((r)=>{
            const { productId } = r[0]
            const promises = [];
            state.images.map((image, idx) => {
                return promises.push(new Promise((resolve, reject) => {

                    try {
                        let vars = {
                            normal: image.fileData.name,
                            productId: productId,
                            imageId : null,
                        }
                        if(image.imageId !== undefined) vars.imageId = image.imageId
                        let result = addImage({
                            variables : vars
                        })
                        addStatus('Saving image details for '+idx+'')
                        resolve(result)
                    } catch (e) {
                        addStatus('Error saving image details for image ' + idx)
                        reject(e)
                    }
                }))

            })


            return Promise.all(promises);
        }).then((r)=>{
            state.result = r
            addStatus('Done')
            setLoading(false)
            if(state.id !== undefined) {
                onFinishCallBack([t('forms.addForm.labels.product_updated',{id:r[0].data.addImage.productId})]);
            }
            else {
                onFinishCallBack([t('forms.addForm.labels.product_created',{id:r[0].data.addImage.productId})]);
            }
        }).catch((e)=>{
            setLoading(false)
            addError(e.toString())
        })


    };

    return (
        <Container>
            <AntiqueBackdrop loading={loading} color={"inherit"} text={"Please wait"}>

                    {
                        loading && status.length? status.map((item, idx)=>{
                            return (
                                <div key={idx}>{item}</div>
                            )
                        }):''
                    }

            </AntiqueBackdrop>
            {errors.length ?
                (
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        <ul>
                            {errors.map((error, idx) => {
                                return <li key={idx}>{error}</li>
                            })}
                        </ul>
                    </Alert>
                ) :
                ''
            }

            <FormStepper activeStep={activeStep()} steps={AddFormStepsTitle} descriptions={AddFormStepsDescription}/>
                <Routes>
                    <Route path={localizeRoutePath(AddFormSteps.Step1)} element={<Step1 formData={state} onUpdate={onUpdate}/>} />
                    <Route path={localizeRoutePath(AddFormSteps.Step2)} element={<Step2 formData={state} onUpdate={onUpdate}/>} />
                    <Route path={localizeRoutePath(AddFormSteps.Step3)} element={<Step3 formData={state} onUpdate={onUpdate}/>} />
                    <Route path={localizeRoutePath(AddFormSteps.Step4)} element={<Step4 formData={state} loading={loading} onSubmitAsync={onSubmitAsync}/>} />
                    <Route path={localizeRoutePath(AddFormSteps.Step5)} element={<Step5/>} />
                    <Route path="*" element={<NoMatch mess={"from form"} />}/>
                </Routes>
        </Container>

    );
}