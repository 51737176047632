import React from "react";
import {Alert, Box, CircularProgress, Dialog, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";

const AntiqueDialog = ({title, open, handleClose, children, loading, errors, description}) => {

    return (
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>{title}
                    {handleClose && <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >

                        <CloseIcon />
                    </IconButton>
                    }
                </DialogTitle>
                <Box sx={{p:2}}>

                    {
                        loading ? <CircularProgress/> : ''
                    }
                    {
                        errors ?
                            <Alert severity="error">
                                <ul>
                                    {
                                        errors.map((error, idx) => {
                                            return (
                                                <li key={idx}>{error.message}</li>
                                            );
                                        })
                                    }
                                </ul>
                            </Alert>
                            :
                            <>
                                {
                                    description && <Typography>{description}</Typography>
                                }
                                {children}
                            </>

                    }
                </Box>
            </Dialog>
    );
}

export default AntiqueDialog;