import React, {useContext, useState} from 'react'
import ShopCard from "../ShopCard/ShopCard";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import {useTranslation} from "react-i18next";
import {UserContext} from "../../contexts/UserContext";
import ContactForm from "../ContactForm/ContactForm";
import AntiqueDialog from "../AntiqueDialog/AntiqueDialog";
import {useNavigate} from "react-router-dom";
import {AppRoute} from "../../const";

const AntiqueAddShopCard = () => {

    const {t} = useTranslation()

    const authUser = useContext(UserContext)

    const navigate = useNavigate()

    const [sellOpen,setSellOpen] = useState(false)

    const handleMoreDetails = () => {
        setSellOpen(true);
    }

    const handleAdd = () => {
        navigate("./.." + t(AppRoute.MyPlace) + t('routes.create').slice(1));
    }

    const handleMoreDetailsClose = () => {
        setSellOpen(false);
    }
    return (
        <>
            <AntiqueDialog
                title={t('shop.card.title')}
                open={sellOpen}
                handleClose={handleMoreDetailsClose}
                description={t('shop.card.description')}
            >
                <ContactForm/>
            </AntiqueDialog>
            <ShopCard description={t('shop.card.description')} title={t('shop.card.title')}>
                <CardActions>
                    <Button size="small" onClick={()=>handleMoreDetails()}>{t('shop.card.buttonText')}</Button>
                    {
                        authUser && authUser.isContributor ?

                            <Button size="small" onClick={()=>handleAdd()}>{t('shop.card.addButtonText')}</Button>
                            :
                            ''
                    }
                </CardActions>
            </ShopCard>
        </>


    )

}

export default AntiqueAddShopCard