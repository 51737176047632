import React from "react"
import {Card, CardActionArea} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const AntiqueErrorCard = ({imgSrc, title, alt, description, onClick}) => {

    return (
        <Card sx={{background:'black', height:'100%'}} variant="outlined">
            <CardActionArea onClick={onClick}>
                <CardMedia
                    component="img"
                    height="140"
                    image={imgSrc}
                    alt={alt}
                />
                <CardContent>
                    <Typography sx={{ fontSize: 20,paddingBottom:2 }} color="white" gutterBottom>
                        {title}
                    </Typography>

                        {description}

                </CardContent>
            </CardActionArea>
        </Card>
    )

}

export default AntiqueErrorCard