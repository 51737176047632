import React from 'react'
import SignInForm from "../SignInForm/SignInForm";
import SignInFormSocial from "../SignInFormSocial/SignInFormSocial";
import {Box, Chip, Divider} from "@mui/material";
import {useTranslation} from "react-i18next";

const AntiqueSignIn = () => {

    const [t] = useTranslation();

    return (
        <>
            <Box sx={{alignItems: 'center',flex: 1, display: {xs: 'block', sm: 'none'}}}>
                <SignInForm/>
                <Divider flexItem={true}>
                    <Chip label={t('forms.signInForm.labels.or')} size="small" />
                </Divider>
                <SignInFormSocial/>
            </Box>
            <Box sx={{alignItems: 'center',flex: 1, display: {xs: 'none', sm: 'flex'}}}>
                <SignInForm/>
                <Divider orientation="vertical" flexItem={true}>
                    <Chip label={t('forms.signInForm.labels.or')} size="small" />
                </Divider>
                <SignInFormSocial/>
            </Box>
        </>
    )
}

export default AntiqueSignIn