import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {Grid, ImageList, Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {AddFormSteps} from "../../../const";
import {useTranslation} from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from '@mui/icons-material/Send';
import AntiqueImageListItem from "../../AntiqueImageListItem/AntiqueImageListItem";

const Step4 = ({formData, onSubmitAsync, loading}) => {

    const navigate = useNavigate();
    const {t} = useTranslation();

    const step3Completed = () => {
        return typeof formData.images !== 'undefined' && formData.images.length;
    }

    const goBack = () => {
        navigate("../" + t(AddFormSteps.Step3));
    }

    const goNext = () => {
       onSubmitAsync()

    }

    useEffect(() => {
        if (!step3Completed()) navigate("../");
    });

    const getFormLabelTranslationFor = (name) => 'forms.addForm.labels.' + name;
    const getFormLabelTranslation = (name, language) => t('forms.addForm.labels.' + name, {language: language});

    return (

        <Grid container spacing={2} margin={2}>
            <Grid item xs={12}>

                {Object.keys(formData).length === 0 ? '' :
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 350}} aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {t(getFormLabelTranslationFor('name'))}
                                    </TableCell>
                                    <TableCell>{formData.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {t(getFormLabelTranslationFor('price'))}
                                    </TableCell>
                                    <TableCell>{formData.price}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {t(getFormLabelTranslationFor('currency'))}
                                    </TableCell>
                                    <TableCell>{formData.currency}</TableCell>
                                </TableRow>
                                {
                                    formData.descriptions.map((desc, idx) => {
                                        return (
                                            <React.Fragment key={idx}>
                                                <TableRow key={"t" + idx}>
                                                    <TableCell component="th" scope="row">
                                                        {t(getFormLabelTranslation('title', desc.language))}
                                                    </TableCell>
                                                    <TableCell>{desc.title}</TableCell>
                                                </TableRow>
                                                <TableRow key={"d" + idx}>
                                                    <TableCell component="th" scope="row">
                                                        {t(getFormLabelTranslation('desc', desc.language))}
                                                    </TableCell>
                                                    <TableCell>{desc.desc}</TableCell>
                                                </TableRow>
                                                <TableRow key={"d2" + idx}>
                                                    <TableCell component="th" scope="row">
                                                        {t(getFormLabelTranslation('longDesc', desc.language))}
                                                    </TableCell>
                                                    <TableCell>{desc.longDesc}</TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        )
                                    })
                                }
                                <TableRow>
                                    <TableCell component="th" scope="row">{t('Images')}</TableCell>
                                    <TableCell component="th" scope="row">
                                        <ImageList
                                            sx={{width: 500, height: 200}}
                                            variant="quilted"
                                            cols={4}
                                            rowHeight={121}
                                        >
                                            {formData.images?.map((item, idx) => {
                                                item.loading = false
                                                return (
                                                    <AntiqueImageListItem key={idx} cols={1} rows={1}
                                                                          image={item}/>
                                                );
                                            })}
                                        </ImageList>
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Grid>

            <Grid item xs={12}>
                <Button startIcon={<ArrowBackIcon/>} onClick={goBack} variant={"outlined"} disabled={loading}>
                    {t('back')}
                </Button>
                <Button startIcon={<SendIcon/>} onClick={goNext} disabled={loading} variant={"contained"}>
                    {t('forms.addForm.labels.save_product')}
                </Button>
            </Grid>

        </Grid>


    );
}

export default Step4;