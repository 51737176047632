import React from "react";
import { Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";

export function AntiqueDescriptionItem (props)
{
    const {  control, errors, index, language  } = props;
    const {t} = useTranslation();




    const getFormLabelTranslationFor = (name) => t('forms.addForm.labels.'+name, {language:language});
    const getFormErrorTranslationFor = (name) => t('forms.addForm.errors.'+name, {language:language});

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={2} md={2}>
                <input
                    type={"hidden"}
                    name={`descriptions[${index}].language`}
                    defaultValue={language}
                    control={control}
                />
                {t(language)}
            </Grid>
            <Grid item xs={12} lg={2} md={2}>
                <Controller
                    name={`descriptions[${index}].title`}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <>
                            <TextField helperText={errors?.title?getFormErrorTranslationFor("title") : ''} onChange={onChange} value={value} label={getFormLabelTranslationFor("title")} name={`descriptions.${index}.title`} error={errors?.hasOwnProperty(`title`)}/>
                        </>


                    )}
                />
            </Grid>
            <Grid item xs={12} lg={2} md={2}>
                <Controller
                    name={`descriptions[${index}].desc`}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <>
                            <TextField helperText={errors?.desc?getFormErrorTranslationFor("desc") : ''} onChange={onChange} value={value} label={getFormLabelTranslationFor("desc")} name={`descriptions.${index}.desc`} error={errors?.hasOwnProperty(`desc`)}/>
                        </>


                    )}
                />
            </Grid>
            <Grid item xs={12} lg={2} md={2}>
                <Controller
                    name={`descriptions[${index}].longDesc`}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <>
                            <TextField helperText={errors?.longDesc?getFormErrorTranslationFor("longDesc") : ''} onChange={onChange} value={value} label={getFormLabelTranslationFor("longDesc")} name={`descriptions.${index}.longDesc`} error={errors?.hasOwnProperty(`longDesc`)}/>
                        </>

                    )}
                />
            </Grid>

        </Grid>
    );
}