import React from 'react'

import {AntiqueAppBar} from "../../components/AntiqueAppBar/AntiqueAppBar";

import {useContext, useEffect} from "react";
import {UserDispatchContext} from "../../contexts/UserContext";
import {signOut2} from "../../services/cognito";
import AntiqueAppFooter from "../../components/AntiqueAppFooter/AntiqueAppFooter";
import Container from "@mui/material/Grid";
import Grid from "@mui/material/Grid";
import AntiqueErrorCard from "../../components/AntiqueErrorCard/AntiqueErrorCard";
import {AppRoute, S3_IMAGE_URL} from "../../const";
import {useNavigate} from "react-router-dom";
import {Alert, AlertTitle, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const SignOut = () => {

    const dispatch = useContext(UserDispatchContext)

    const navigate = useNavigate();
    useEffect(()=>{
        signOut2(()=>{
            dispatch({
                type: 'signOut'
            })
        }, ()=>{
            dispatch({
                type: 'signOut'
            })
        })


    })
    const getImgSrc = (name) => {
        return S3_IMAGE_URL+ name
    }
    const [t, i18n] = useTranslation()
    const returnLink = (url) => {
        return `/${i18n.resolvedLanguage}` + t(url);
    }
    const navigateToIndex = () => {
        navigate(returnLink(t(AppRoute.Index)))
    }

    const desc = () => {
        return <Alert severity="warning">
            <AlertTitle>{t('loggedOut.title')}</AlertTitle>
            <Typography>{t('loggedOut.click')}</Typography>
        </Alert>
    }

    return (
        <div>
            <AntiqueAppBar/>
            <div style={{width: '100%'}}>
                <Container style={{height: '100%'}}>
                    <Container
                        sx={{
                            mt: 3,
                            mb: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Grid container sx={{width:'50%'}}>
                            <Grid item xs={12} md={12}>
                                <AntiqueErrorCard title={t('loggedOut.title')} alt={t('loggedOut.title')} description={desc()} onClick={navigateToIndex} imgSrc={getImgSrc('index.jpg')}/>
                            </Grid>

                        </Grid>
                    </Container>
                </Container>
            </div>
            <AntiqueAppFooter/>
        </div>
    )
}

export default SignOut