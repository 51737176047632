import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import {S3_IMAGE_URL, NO_IMAGE_AVAILABLE_URL} from "../../const";

const ShopItem = ({id, name, price, currency, descriptions, images, children}) =>{

    const {t, i18n} = useTranslation();

    let imgSrc = NO_IMAGE_AVAILABLE_URL;
    if(images?.length>0) imgSrc = S3_IMAGE_URL + "items/item"+id+"/"+images[0].normal;

    const tname = descriptions.find((e)=>{
        return e.language === i18n.resolvedLanguage.toUpperCase();

    })?.title
    const tdesc = descriptions.find((e)=>{
        return e.language === i18n.resolvedLanguage.toUpperCase();

    })?.desc

    return (
    <Card sx={{ maxWidth: 345, '&:hover': {
            backgroundColor: 'primary.second',
            opacity: [0.9, 0.8, 0.7],
        }, }} >
        <CardMedia
            component="img"
            alt={name}
            height="140"
            image={imgSrc}
        />
        <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {t(tname)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {t(tdesc)}
            </Typography>
            <Typography variant="body1" color="text.secondary">
                {price} {currency}
            </Typography>
        </CardContent>
        {
            children
        }

    </Card>
    );
}

export default ShopItem;
