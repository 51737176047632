import React from "react"
import i18next from "i18next";
import {portofolio} from "../../const/portofolio";
import {useTranslation} from "react-i18next";
import AntiquePortofolioCard from "../AntiquePortofolioCard/AntiquePortofolioCard";
import {Box, Grid} from "@mui/material";

const AntiquePortofolio = () => {

    const {t} = useTranslation();

    const addTranslations = (portofolio) => {
        const languages = [];
        portofolio.map(item => {

            Object.keys(item.int).map(lang => {
                if(typeof languages[lang] === 'undefined')
                {
                    languages[lang] = {
                        portofolio: {
                            items: {}
                        }
                    }
                }
                languages[lang].portofolio.items["item"+item.id] = item.int[lang];
                return item;
            });
            return item;
        });
        Object.keys(languages).map(lang=>{
            i18next.addResourceBundle(lang, 'translation', languages[lang], true, true);
            return lang;
        });

    }

    addTranslations(portofolio);


    return (

        <Box sx={{width:'100%'}}>
            <Grid container sx={{width:'100%'}}>
                {

                    portofolio.length > 0 ?

                        portofolio.map(item => {
                            return (
                                <Grid item xs={12} sx={{m:1}} key={item.id}>
                                    <AntiquePortofolioCard
                                        title={t('portofolio.items.item'+item.id+'.title')}
                                        description={t('portofolio.items.item'+item.id+'.desc')}
                                        id={item.id}
                                        date={item.date}
                                    />
                                </Grid>
                            )

                        }) : ''
                }
            </Grid>
        </Box>
    )
}

export default AntiquePortofolio;