import React from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import { StateMachineProvider, createStore } from "little-state-machine";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import NoMatch from "./NoMatch/NoMatch";
import FormStepper from "../FormStepper/FormStepper";
import {FormStepsTitle, FormSteps, FormStepsDescription} from "../../const";
import {useTranslation} from "react-i18next";
import Container from "@mui/material/Container";

createStore(
    {
        firstName: '',
        lastName: '',
        email : '',
        details : '',
        phone : '',
        county : '',
        items: [
            {
                width: '',
                height: '',
                name: '',
                image : {
                    content : '',
                    fileData : {
                        size : 0,
                        name : '',
                        type : ''
                    },
                    index : ''
                }
            }]
    });


export default function QuoteForm() {

    const location = useLocation();
    const {t} = useTranslation();

    const activeStep = () =>{
        const path = location.pathname?.substring(location.pathname?.lastIndexOf('/')+1);
        switch (path){
            case t(FormSteps.Step2):
                return 1;
            case t(FormSteps.Step3):
                return 2;
            case t(FormSteps.Step4):
                return 3;
            case t(FormSteps.Step1):
                return 0;
            default:
                return -1;
        }
    }

    function localizeRoutePath(path) {
        switch (typeof path) {
            case 'undefined':
                return undefined;
            default:
                if(path === '*') return path;
                let path2 = t(path)
                if(path2[path2.length-1] === '/' && path2.length > 1) path2 = path2 + '*'
                return path2
        }
    }



    return (
        <Container>
            <FormStepper activeStep={activeStep()} steps={FormStepsTitle} descriptions={FormStepsDescription}/>
            <StateMachineProvider>
                <Routes>
                    <Route path={localizeRoutePath(FormSteps.Step1)} element={<Step1/>} />
                    <Route path={localizeRoutePath(FormSteps.Step2)} element={<Step2/>} />
                    <Route path={localizeRoutePath(FormSteps.Step3)} element={<Step3/>} />
                    <Route path={localizeRoutePath(FormSteps.Step4)} element={<Step4/>} />
                    <Route path="*" element={<NoMatch mess={"from form"} />}/>
                </Routes>
            </StateMachineProvider>
        </Container>

    );
}