import React, {useContext} from "react";
import {ApolloClient, InMemoryCache, ApolloProvider, HttpLink} from '@apollo/client';
import {UserContext} from "../contexts/UserContext";
import {AppRoute, GRAPHQL_ENDPOINT} from "../const";
import {onError} from "@apollo/client/link/error";
import {useTranslation} from "react-i18next";

const AntiqueApolloProvider = ({children}) =>{


    const {t, i18n} = useTranslation();
    const context = useContext(UserContext)

    const logoutLink = onError(({ networkError, operation, forward }) => {
        if (networkError?.statusCode === 401) {
            window.location.replace('/'+i18n.resolvedLanguage + t(AppRoute.SignOut));
            return forward(operation)
        }
    })

    let headers = {
        'x-api-key': process.env.REACT_APP_GRAPHQL_API_KEY
    }

    if(context) {
        const accessToken = context.JwtToken;
        if(accessToken) {
            headers = {}
            headers.Authorization = accessToken
        }
    }

    const httpLink = new HttpLink({
        uri: GRAPHQL_ENDPOINT,
        headers: headers
    })

    const client = new ApolloClient({
        link: logoutLink.concat(httpLink),
        cache: new InMemoryCache(),
    });

    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
}

export default AntiqueApolloProvider