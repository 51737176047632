import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Grid";
import Item from "@mui/material/Grid";

const AntiqueBackdrop = ({
    loading, children, text, color
                         }) => {

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <Container>
                <Grid container spacing={"5"}>
                    <Grid item xs={12} align={"center"}>
                        <Item>
                            {text}
                        </Item>
                    </Grid>
                    <Grid item xs={12} align={"center"}>
                        {children}
                    </Grid>
                    <Grid item xs={12} align={"center"}>
                        <Item>
                            <CircularProgress color={color} />
                        </Item>
                    </Grid>
                </Grid>


            </Container>
        </Backdrop>
    );
}

export default AntiqueBackdrop;