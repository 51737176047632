import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FormInput} from "../FormInput/FormInput";
import {useTranslation} from "react-i18next";
import {AppRoute} from "../../const";
import {signUpUserWith} from "../../services/cognito"
import {useState} from "react";
import AntiqueBackdrop from "../AntiqueBackdrop/AntiqueBackdrop";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {FormCheckbox} from "../FormCheckBox/FormCheckBox";
import {useNavigate} from "react-router-dom";

const SignUpForm = ({isSeller}) => {

    const [t] = useTranslation();

    const schema = yup.object().shape({
        email: yup.string().required('forms.signUpForm.errors.userNameRequired').email('forms.signUpForm.errors.userName'),
        password: yup.string().required().min(8,'forms.signUpForm.errors.passwordMin').max(50,'forms.signUpForm.errors.passwordMax').matches(/[A-Z]/, 'forms.signUpForm.errors.passwordUppercase').matches(/[0-9]/, 'forms.signUpForm.errors.passwordNumeric').matches(/[!@#$%^&*]/, 'forms.signUpForm.errors.passwordSymbol'),
        familyName: yup.string().required('forms.signUpForm.errors.familyRequired').min(5,'forms.signUpForm.errors.familyMin').max(50,'forms.signUpForm.errors.familyMax'),
        givenName: yup.string().required('forms.signUpForm.errors.givenRequired').min(5,'forms.signUpForm.errors.givenMin').max(50,'forms.signUpForm.errors.givenMax'),
        newsletter: yup.boolean().required().oneOf([true, false],'Message'),
        phone: yup.string().when("phone", (val) => {
            if (val?.length > 0) {  //if address exist then apply min max else not
                return yup.string().min(10,'forms.signUpForm.errors.phoneMin').max(20, 'forms.signUpForm.errors.phoneMax').required('forms.signUpForm.errors.phoneRequired');
            } else {
                return yup.string().notRequired();
            }
        }),
        seller: yup.string().when("seller", ()=>{
            if(isSeller) {
                return yup.boolean().required().oneOf([true],'Message')
            } else {
                return yup.boolean().notRequired();
            }
        })
    }, [
        ["phone", "phone"],
        ["seller", "seller"],
    ]
    );

    const [signUpErrors, setSignUpErrors] = useState([])
    const [loading, setLoading] = useState(false)

    const defaultValues = {email:'',password:'',familyName:'',givenName:'',phone:'',newsletter:true,seller:isSeller}

    const {handleSubmit, control, formState: {errors}, reset} = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const onSubmit = (data) => {
        setLoading(true)
        signUpUserWith(data.email, data.password, data.familyName, data.givenName, data.phone, data.newsletter?"1":"0", isSeller?"1":"0").then(()=>{
            setLoading(false)
            if(isSeller) navigate("./../.."+t(AppRoute.SignUp)+t('routes.confirm'))
            else navigate("./.."+t(AppRoute.SignUp)+t('routes.confirm'))
        }).catch((errors)=>{
            setSignUpErrors([errors.toString()])
            setLoading(false)
        })
    }


    const navigate = useNavigate()

    const getLabel = (label) => {
        return t('forms.signUpForm.labels.'+label)
    }

    const signInLink = () => {
        navigate("./.."+(isSeller?"/..":"")+t(AppRoute.SignIn));
    }

    const signUpSellerLink = () => {
        navigate("./.."+t(AppRoute.SignUp)+t('routes.seller'));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <AntiqueBackdrop loading={loading} color={"inherit"} text={t('sendingForm')}/>
            {signUpErrors.length ?
                (
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        <ul>
                            {signUpErrors.map((error, idx) => {
                                return <li key={idx}>{error}</li>
                            })}
                        </ul>
                    </Alert>
                ) :
                ''
            }
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {isSeller?t(getLabel('sellerSignUp')):t(getLabel('signUp'))}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormInput name={"email"} control={control} errors={errors}
                                       label={t(getLabel("emailAddress"))} InputProps={{
                                margin: "normal",
                                required: true,
                                disabled : loading,
                                fullWidth: true,
                                id: "email",
                                helperText : errors?.email?.message ? t(errors.email.message) : ''
                            }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput name={"password"} control={control} errors={errors} label={t(getLabel("password"))} InputProps={{
                                margin:"normal",
                                required:true,
                                fullWidth:true,
                                type:"password",
                                id:"password",
                                disabled : loading,
                                helperText : errors?.password?.message ? t(errors.password.message) : ''
                            }}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormInput name={"familyName"} control={control} errors={errors}
                                       label={t(getLabel("familyName"))} InputProps={{
                                margin: "normal",
                                fullWidth: true,
                                required: true,
                                id: "familyName",
                                disabled : loading,
                                helperText : errors?.familyName?.message ? t(errors.familyName.message) : ''
                            }}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormInput name={"givenName"} control={control} errors={errors}
                                       label={t(getLabel("givenName"))} InputProps={{
                                margin: "normal",
                                fullWidth: true,
                                required: true,
                                id: "givenName",
                                disabled : loading,
                                helperText : errors?.givenName?.message ? t(errors.givenName.message) : ''
                            }}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormInput name={"phone"} control={control} errors={errors}
                                       label={t(getLabel("phone"))} InputProps={{
                                margin: "normal",
                                fullWidth: true,
                                id: "phone",
                                disabled : loading,
                                helperText : errors?.phone?.message ? t(errors.phone.message) : ''
                            }}/>
                        </Grid>
                        {
                            isSeller ?
                                <Grid item xs={12}>
                                    <FormCheckbox name={"seller"} InputProps={{disabled:loading}} control={control} label={getLabel("seller")} errors={errors}/>
                                </Grid>
                                :
                                ''
                        }
                        <Grid item xs={12}>
                            <FormCheckbox name={"newsletter"} InputProps={{disabled:loading}} control={control} label={getLabel("newsletter")} errors={errors}/>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        {t(getLabel("signUp"))}
                    </Button>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end">
                            <Link onClick={(e)=>{e.preventDefault(); signInLink()}} component={"button"} variant="body2">
                                {t(getLabel("alreadyAccount"))}
                            </Link>
                            </Box>
                        </Grid>
                        {
                            isSeller ?
                                ''
                                :
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="flex-end">
                                    <Link onClick={(e)=>{
                                        e.preventDefault();
                                        defaultValues.seller = true
                                        reset(defaultValues);
                                        signUpSellerLink()
                                    }} component={"button"} variant="body2">
                                        {t(getLabel("createSellerAccount"))}
                                    </Link>
                                    </Box>
                                </Grid>
                        }

                    </Grid>
                </Box>
            </Container>
        </form>
    );
}
export default SignUpForm