import React from "react";
import { Controller } from "react-hook-form";
import {Checkbox, FormControlLabel} from "@mui/material";

export function FormCheckbox (props)
{

    const {  name, control, label, errors, InputProps } = props;

    return (
        <FormControlLabel
            control={
                <Controller
                    name={name}
                    control={control}
                    render={({field: {onChange, value, name}}) => (
                        <>
                            <Checkbox {...InputProps} checked={value} onChange={onChange} name={name}/>
                            {errors?.hasOwnProperty(name)}
                        </>


                    )}
                />
            }
            label={label} />
    );
}