import React, {useState} from 'react';
import {AppLanguage} from '../../const';
import { useLocation, useNavigate   } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {Box} from "@mui/material";

export const LanguageSwitcher = (props) => {

    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [lang, setLang] = useState(i18n.resolvedLanguage);


    const {color} = props

    function getMatchingRoute(language) {
        const parts = pathname.split('/');

        const currentLanguage = i18n.resolvedLanguage;

        let url = `/${language}`

        for(let i=2;i<parts.length;i++)
        {
            if(parts[i].length === 0) continue;

            let routeKey = Object.keys(
                i18n.options.resources[currentLanguage].translation.routes).find(
                    key => i18n.options.resources[currentLanguage].translation.routes[key].toLowerCase().includes(parts[i].toLowerCase())

            );
            if(routeKey === "edit") {
                let matchingRoute = i18n.options.resources[language].translation.routes[routeKey];
                if(url.substring(url.length-1) === '/' && matchingRoute[0] === '/') url = url + matchingRoute.substring(1)
                else if(url.substring(url.length-1) !== '/' && matchingRoute[0] !== '/') url = url + '/' + matchingRoute
                else url = url + matchingRoute
                if(url.substring(url.length-1) === '/') url = url + parts[++i]
                else url = url + '/' + parts[++i]
                continue
            }
            let matchingRoute = i18n.options.resources[language].translation.routes[routeKey];
            if(url.substring(url.length-1) === '/' && matchingRoute[0] === '/') url = url + matchingRoute.substring(1)
            else if(url.substring(url.length-1) !== '/' && matchingRoute[0] !== '/') url = url + '/' + matchingRoute
            else url = url + matchingRoute
        }



        /**
         * Return localized route
         */
        return url;
    }

    const handleChange = (event) =>{



        navigate(getMatchingRoute(event.target.value));
        i18n.changeLanguage(event.target.value);
        setLang(event.target.value);

    }

    return (
        <Box>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120, color:color}}>
        <InputLabel id="demo-simple-select-label" sx={{color:color}}>{t('language')}</InputLabel>
        <Select
            sx={{color:color}}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={lang}
            label={t('language')}
            onChange={handleChange}>
            {Object.keys(AppLanguage).map((lang) => (
                <MenuItem value={lang} key={lang}>
                    {AppLanguage[lang]}
                </MenuItem>

            ))}
        </Select>
        </FormControl>
        </Box>
    );
};