import {gql, useQuery} from "@apollo/client";

const useList = (variable) => {


    const GET_MY_PRODUCTS = gql`
  query QueryMyProducts($limit: Int, $nextToken: String) {
    getMyProducts(limit: $limit, nextToken: $nextToken) {
      products {
          currency
          descriptions {
            language
            desc
            longDesc
            title
            descriptionId
          }
          id
          name
          price
          status
          images {
            normal
            thumb
            imageId
          }
      }
      nextToken
    }
  }

`;

    const {loading, error, data, refetch } = useQuery(GET_MY_PRODUCTS, {
        variables: variable,
        nextFetchPolicy: "no-cache"
    });



    return {loading, error, data, refetch}


}

export default useList