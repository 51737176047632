import React from "react";
import {AntiqueAppBar} from "../../components/AntiqueAppBar/AntiqueAppBar"
import AntiqueAppFooter from "../../components/AntiqueAppFooter/AntiqueAppFooter";
import {useTranslation} from "react-i18next";
import {Box, Card, Grid} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import {AppRouteTitle, S3_IMAGE_URL} from "../../const";
import SignUpForm from "../../components/SignUpForm/SignUpForm";
import {Route, Routes} from "react-router-dom";
import SignUpConfirmForm from "../../components/SignUpConfirmForm/SignUpConfirmForm";
import NoMatch from "../../components/AntiqueProductForm/NoMatch/NoMatch";
import {Helmet, HelmetProvider} from "react-helmet-async";
const SignIn = () => {

    const {t} = useTranslation();
    const imgSrc = S3_IMAGE_URL + "index.jpg"

    return (
        <div>
            <HelmetProvider><Helmet>
                <title>{t(AppRouteTitle.SignIn)}</title>
                <meta name="description" content={t('signUp.description')} />
                <meta name="keywords" content={t('signUp.keywords')} />
            </Helmet></HelmetProvider>
            <AntiqueAppBar/>
            <div style={{
                width: '100%',
            }}>
                <Grid container>
                    <Grid item xs={12} lg={4}/>
                    <Grid item xs={12} lg={4}>
                    <Card sx={{ maxWidth: '100%', m:1 }}>

                        <CardMedia
                            component="img"
                            height="140"
                            image={imgSrc}
                            alt={t('title.signIn')}
                        />
                        <CardContent>
                            <Box sx={{p: 0}}>
                                <Routes>
                                    <Route path={t('routes.seller')} element={<SignUpForm isSeller={true}/>} />
                                    <Route path={t('routes.confirm')} element={<SignUpConfirmForm/>} />
                                    <Route path="" element={<SignUpForm/>} />
                                    <Route path="*" element={<NoMatch mess={"from form"} />}/>
                                </Routes>
                            </Box>
                        </CardContent>
                    </Card>
                    </Grid>
                </Grid>
            </div>
            <AntiqueAppFooter/>
        </div>
    )
}

export default SignIn;