import React, {useEffect, useState} from "react";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Alert, Box, Card, CircularProgress, Divider, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Product, S3_IMAGE_URL} from "../../../const";
import ShopItem from "../../../components/ShopItem/ShopItem";
import {useNavigate} from "react-router-dom";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import AddCardIcon from '@mui/icons-material/AddCard';
import AntiqueCard from "../../../components/AntiqueCard/AntiqueCard";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import useList from "./useList";
import Delete from "../delete/Delete";

const List = () => {

    const variable =  {
        limit : 20
    }




    const {t} = useTranslation();
    const imgSrc = S3_IMAGE_URL + "index.jpg"
    const navigate = useNavigate();
    const [nextToken, setNextToken] = useState('')

    if(nextToken.length > 0) {
        variable.nextToken = nextToken
    }

    const {loading, data, error, refetch} = useList(variable)



    const [products, setProducts] = useState([])
    const [state, setState] = useState({open:false, id:''})

    useEffect(()=>{
        if(!loading && data && !error) {
            if(data.getMyProducts.products.length > 0) {
                if (nextToken.length > 0) {
                    setProducts((p) => [...p, ...data.getMyProducts.products])
                } else {
                    setProducts(() => [...data.getMyProducts.products])
                }
            }
        }
    },[loading, data, error, nextToken])

    const onEdit = (id) => {
        navigate('.' + t(Product.edit) + '/' + id);
    }

    const onDelete = (id) => {
        setState({open: true, id:id});
    }

    const handleClose = () => {
        setState({open: false, id:''});
    }

    const onDeleted = () => {
        setNextToken('')
        refetch(variable).then(()=>{
            setState({open: false, id:''});
        })
    }

    const handleAdd = () => {
        navigate('.' + t(Product.create));
    }



    return (
        <Card sx={{maxWidth: '100%', m: 1}}>
            {
                state.open ? <Delete open={state.open} product={products.find((p)=>p.id === state.id)} onClose={()=>handleClose()} onDeleted={()=>onDeleted()}/> : ''
            }
            <CardMedia
                component="img"
                height="140"
                image={imgSrc}
                alt={t('title.myplace')}
            />
            <CardContent>
                <Typography variant="h5" component="h2">{t('title.listMyProducts')}</Typography>
                <Divider>{t('products')}</Divider>
                <Box sx={{p: 2}}>

                    {
                        loading ? <CircularProgress/> :
                            error ?

                                <Alert severity="error">
                                    <ul>
                                        {
                                            error.graphQLErrors.map(error => {
                                                return (
                                                    <li>{error.message}</li>
                                                );
                                            })
                                        }
                                    </ul>
                                </Alert>

                                :
                                <Grid container spacing={2}>
                                    {
                                        products.map(item => {
                                            return (
                                                <Grid item md={4} xs={12} sm={6} lg={3} key={item.id}>
                                                    <ShopItem onEdit={onEdit}  {...item}>
                                                        <CardActions>
                                                            <Button size="small"
                                                                    onClick={() => onEdit(item.id)}>{t('shop.edit')}</Button>
                                                            <Button size="small"
                                                                    onClick={() => onDelete(item.id)}>{t('shop.delete')}</Button>
                                                        </CardActions>
                                                    </ShopItem>
                                                </Grid>
                                            );
                                        })
                                    }



                                    {
                                        !loading && !error && data?.getMyProducts?.nextToken?.length > 0 ?
                                            <Grid item md={4} xs={12} sm={6} lg={3} key={10000}>
                                                <AntiqueCard onClick={() => setNextToken(data.getMyProducts.nextToken)} title={t('shop.moreTitle')} icon={<MoreHorizIcon/>}/>
                                            </Grid>
                                            :
                                            ''
                                    }

                                    <Grid item md={4} xs={12} sm={6} lg={3} key={100}>
                                        <AntiqueCard onClick={handleAdd} title={t('title.createProduct')} icon={<AddCardIcon/>}/>
                                    </Grid>

                                </Grid>


                    }

                </Box>
            </CardContent>
        </Card>
    )

}

export default List