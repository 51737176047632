import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useContext} from "react";
import {UserContext} from "../../contexts/UserContext";
import {AppRoute, AppRouteTitle} from "../../const";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
const AntiqueUserMenu = () => {

    const {t, i18n} = useTranslation();

    const userAuth = useContext(UserContext)

    let links = []

    if(userAuth?.isContributor) {
        links.push({
            name: AppRouteTitle.MyPlace,
            link: AppRoute.MyPlace,
        })
    }

    links.push({
            name: AppRouteTitle.MyProfile,
            link: AppRoute.MyProfile,
        },
        {
            name: AppRouteTitle.SignOut,
            link: AppRoute.SignOut,
        })

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const returnLink = (url) => {
        return `/${i18n.resolvedLanguage}` + t(url);
    }

    const AvatarName = userAuth?.FamilyName? userAuth.FamilyName.substr(0,1) + ' ' + userAuth.GivenName.substr(0,1) : <AccountCircleIcon/>

    return (
        <>
        <Box sx={{flex: 1, display: {xs: 'block', sm: 'none'}, justifyContent: 'flex-end'}}>
            {
                userAuth ?
                    links.map((item,idx)=>{
                        return (
                            <Link key={idx} to={returnLink(item.link)}>
                                <MenuItem onClick={handleCloseUserMenu}>
                                    {t(item.name)}
                                </MenuItem>
                            </Link>

                        )
                    })
                    :
                    <Link to={returnLink(AppRoute.SignIn)}>
                        <MenuItem onClick={handleCloseUserMenu}>
                            {t(AppRouteTitle.SignIn)}
                        </MenuItem>
                    </Link>
            }
        </Box>
        <Box sx={{flex: 1, display: {xs: 'none', sm: 'flex'}, color: 'white', justifyContent: 'flex-end', width:'15%'}}>
            <Tooltip title="User actions">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar>{AvatarName}</Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="usermenu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseUserMenu}
            >
                {
                    userAuth ?
                        links.map((item,idx)=>{
                            return (
                                <Link key={idx} to={returnLink(item.link)}>
                                    <MenuItem onClick={handleCloseUserMenu}>
                                        {t(item.name)}
                                    </MenuItem>
                                </Link>

                            )
                        })
                        :
                        <Link to={returnLink(AppRoute.SignIn)}>
                            <MenuItem onClick={handleCloseUserMenu}>
                                {t(AppRouteTitle.SignIn)}
                            </MenuItem>
                        </Link>
                }

            </Menu>
        </Box>
        </>
    )
}

export default AntiqueUserMenu