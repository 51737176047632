import React from "react";
import {AntiqueAppBar} from "../../components/AntiqueAppBar/AntiqueAppBar"
import AntiqueAppFooter from "../../components/AntiqueAppFooter/AntiqueAppFooter";
import {useTranslation} from "react-i18next";
import QuoteForm from "../../components/QuoteForm/QuoteForm";
import Typography from "@mui/material/Typography";
import {Box, Card, Container} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import {AppRouteTitle, S3_IMAGE_URL} from "../../const";
import {Helmet, HelmetProvider} from "react-helmet-async";

const GetAQuote = () => {

    const {t} = useTranslation();
    const imgSrc = S3_IMAGE_URL + "index.jpg"

    return (
        <div>
            <HelmetProvider><Helmet>
                <title>{t(AppRouteTitle.GetAQuote)}</title>
                <meta name="description" content={t('Quote.description')} />
                <meta name="keywords" content={t('Quote.keywords')} />
            </Helmet></HelmetProvider>
            <AntiqueAppBar/>
            <div style={{
                width: '100%',
            }}>
                <Container>
                    <Card sx={{ maxWidth: '100%', m:1 }}>

                            <CardMedia
                                component="img"
                                height="140"
                                image={imgSrc}
                                alt={t('title.getaquote')}
                            />
                            <CardContent>
                            <Typography variant="h5" component="h2">{t('title.getaquote')}</Typography>
                            <Box sx={{p: 0}}>
                                <QuoteForm/>
                            </Box>
                            </CardContent>
                    </Card>
                </Container>
            </div>
            <AntiqueAppFooter/>
        </div>
    )
}

export default GetAQuote;