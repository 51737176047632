import React, {useEffect} from 'react';
import {Grid} from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {useLocation, useNavigate} from "react-router-dom";

const Step5 = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const step4Completed = () =>{
        return state !== null && state.result !==undefined && state.result.length > 0;
    }

    useEffect(() => {
        if(!step4Completed()) navigate("../");
    });

    return (
        <Grid container spacing={2} margin={2}>
            <Grid item xs={12}>{state !== null && state.result.length ?
                (
                    <Alert severity="info">
                        <AlertTitle>Info</AlertTitle>
                        {state.result.map((item, idx) => {
                            return <b key={idx}>{item}</b>
                        })}
                    </Alert>
                ) :
                <></>
            }
            </Grid>
        </Grid>
    );
}

export default Step5;