import React from "react";
import { Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Button  from '@mui/material/Button';
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";

export function FormItem (props)
{
    const {  control, errors, index, setValue  } = props;
    const {t} = useTranslation();
    const fileUpload = React.createRef();
    const showFileUpload = () => {
        fileUpload.current.click();
    }

    function handleFiles(file) {

            const local = { content : '', fileData : {name : '', size: 0, type : ''}, index: index};

            if (!file.type.startsWith('image/')){ return local; }



            if(file.size > 100000) { return local; }


            local.fileData.size = file.size;
            local.fileData.name = file.name;
            local.fileData.type = file.type;

            const reader = new FileReader();
            reader.onload = (function (e) {
                    local.content = e.target.result
                    setValue(`items.${index}.image`, local, {
                        shouldValidate: true,
                        shouldDirty: true
                    });
                });
            reader.readAsDataURL(file);
            return local;
    }

    const getFormLabelTranslationFor = (name) => 'forms.quoteForm.labels.'+name;
    const getFormErrorTranslationFor = (name) => 'forms.quoteForm.errors.'+name;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={2} md={2}>
            <Controller
                name={`items[${index}].name`}
                control={control}
                render={({field: {onChange, value}}) => (
                    <>
                        <TextField helperText = {errors && errors?.name ? t(getFormErrorTranslationFor("item_name")) : ''} onChange={onChange} value={value} label={t(getFormLabelTranslationFor("item_name"))} name={`items.${index}.name`} error={!!(errors && errors?.name)}/>
                    </>


                )}
            />
            </Grid>
            <Grid item xs={12} lg={2} md={2}>
            <Controller
                name={`items[${index}].width`}
                control={control}
                render={({field: {onChange, value}}) => (
                    <>
                        <TextField type={"number"} helperText = {errors && errors?.width ? t(getFormErrorTranslationFor("item_width")) : ''} onChange={onChange} value={value} label={t(getFormLabelTranslationFor("item_width"))} name={`items.${index}.width`} error={!!(errors && errors?.width)}/>
                    </>


                )}
            />
            </Grid>
            <Grid item xs={12} lg={2} md={2}>
            <Controller
                name={`items[${index}].height`}
                control={control}
                render={({field: {onChange, value}}) => (
                    <>
                        <TextField type={"number"} helperText = {errors && errors?.height ? t(getFormErrorTranslationFor("item_height")) : ''} onChange={onChange} value={value} label={t(getFormLabelTranslationFor("item_height"))} name={`items.${index}.height`} error={!!(errors && errors?.height)}/>
                    </>

                )}
            />
            </Grid>
            <Grid item xs={12} lg={2} md={2}>
            <Controller
                name={`items[${index}].image`}
                control={control}
                render={({field: {onChange, value}}) => (
                    <>
                        <input ref={fileUpload} type="file" accept="image/*" onChange={(evt)=>onChange(handleFiles(evt.target.files[0]))} name={`items.${index}.image`} style={{display:'none'}}/>
                        <div className="invalid-feedback">{errors?.image?.fileData?.name?t(getFormErrorTranslationFor("item_image_name")):''}</div>
                        <div className="invalid-feedback">{errors?.image?.fileData?.size?t(getFormErrorTranslationFor("item_image_size")):''}</div>
                        {value.fileData?.name ? <b>{value.fileData.name}</b> : <Button onClick={()=>showFileUpload()}>{t(getFormLabelTranslationFor("select_image"))}</Button>}
                    </>

                )}
            />
            </Grid>

        </Grid>
    );
}