export default function UserReducer(currentState, action) {
    switch (action.type) {
        case 'signIn': {
            if(localStorage.getItem('AntiqueAppUserRemember')) {
                localStorage.setItem('AntiqueAppUser', JSON.stringify(action.response))
            } else{
                sessionStorage.setItem('AntiqueAppUser', JSON.stringify(action.response))
            }

            return action.response
        }
        case 'updateUser': {
            const authObject = {
                JwtToken : currentState.JwtToken,
                Expiration : currentState.Expiration,
                FamilyName : action.response.FamilyName,
                GivenName : action.response.GivenName,
                PhoneNumber : action.response.PhoneNumber,
                Email :currentState.Email,
                UserId : currentState.UserId,
                Newsletter : action.response.Newsletter,
                isContributor : currentState.isContributor,
                RememberExpiration : currentState.RememberExpiration,
            }
            if(localStorage.getItem('AntiqueAppUserRemember')) {
                localStorage.setItem('AntiqueAppUser', JSON.stringify(authObject))
            }
            else{
                sessionStorage.setItem('AntiqueAppUser', JSON.stringify(authObject))
            }
            return authObject
        }
        case 'signOut' : {
            if(localStorage.getItem('AntiqueAppUserRemember')) {
                localStorage.removeItem('AntiqueAppUser')
            }
            else{
                sessionStorage.removeItem('AntiqueAppUser')
            }
            localStorage.removeItem('AntiqueAppUserRemember')
            return action.response
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }

}