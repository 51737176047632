import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AddFormSteps} from "../../../const";
import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AntiqueImageUploader from "../../AntiqueImageUploader/AntiqueImageUploader";
import {useAntiqueProductForm} from "../useAntiqueProductForm";


const Step3 = ({formData, onUpdate}) => {

    const navigate = useNavigate();
    const {t} = useTranslation();

    const {removeImage} = useAntiqueProductForm();


    const step2Completed = () => {
        return typeof formData.descriptions !== 'undefined' && formData.descriptions.length > 0;
    }

    const onSubmit = () => {
        navigate("../" + t(AddFormSteps.Step4));
    };

    const goBack = () => {
        navigate("../" + t(AddFormSteps.Step2));
    }

    const remove = (image) => {
        if (image.imageId) {
            image.loading = true
            return removeImage({
                variables: {
                    imageId: image.imageId,
                    productId: formData.id
                }
            })
        }
        return new Promise((resolve)=>{resolve(true)});
    }

    const add = () => {
    }

    const update = (newImages) => {
        onUpdate({images: newImages})
    }


    useEffect(() => {
        if (!step2Completed()) navigate("../");
    });

    return (
        <Grid container spacing={2} margin={2}>
            <Grid item xs={12}>
                <AntiqueImageUploader add={add} images={formData.images} remove={remove} update={update}/>
            </Grid>
            <Grid item xs={12}>
                <Button startIcon={<ArrowBackIcon/>} onClick={goBack} variant={"outlined"}>
                    {t('back')}
                </Button>
                <Button type="submit" startIcon={<ArrowForwardIcon/>} onClick={onSubmit}
                        disabled={formData.images ===undefined || formData.images?.length <= 0} variant={"contained"}>
                    {t('next')}
                </Button>
            </Grid>
        </Grid>

    )


}

export default Step3