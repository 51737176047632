import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {AddFormSteps, AppLanguage} from "../../../const";
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";
import * as Yup from "yup";
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as yup from "yup";
import {AntiqueDescriptionItem} from "../../AntiqueDescriptionItem/AntiqueDescriptionItem";

const validationSchema = Yup.object().shape({
    descriptions: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Title is required'),
            desc: Yup.string().required('Short description is required'),
            longDesc: Yup.string().required('Long Description is required'),
            language: yup.mixed().oneOf(Object.keys(AppLanguage).map((e) => e.toUpperCase())).required(),
        })
    )
});


const Step2 = ({formData, onUpdate}) => {


    const getDefaultItem = (language) => {
        return {
            title: '',
            language: language.toUpperCase(),
            desc: '',
            longDesc: ''
        };
    }
    const navigate = useNavigate();
    const {t} = useTranslation();

    const populateDefaultValues = () => {
        let itemsCount = formData.descriptions?.length;
        if (itemsCount === undefined || itemsCount === 0) {
            let descriptions = [];
            Object.keys(AppLanguage).map((e) => {
                descriptions.push(getDefaultItem(e.toUpperCase()))
                return true;
            })
            return {descriptions: descriptions};
        }
        let newValues = [];
        formData.descriptions.map((item) => {
            newValues.push(item);
            return true;
        })
        return {descriptions: newValues};
    }


    const {handleSubmit, control, formState: {errors}, reset} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: populateDefaultValues()
    });

    const {fields} = useFieldArray({name: 'descriptions', control});

    const step1Completed = () => {
        return typeof formData.name !== 'undefined' && formData.name.length > 0;
    }

    const onSubmit = (data) => {
        onUpdate(data).then(()=>{
            navigate("../" + t(AddFormSteps.Step3));
        })
    };

    const goBack = () => {
        navigate("../");


    }

    const onReset = () => {
        let descriptions = [];
        Object.keys(AppLanguage).map((e) => {
            descriptions.push(getDefaultItem(e.toUpperCase()))
            return true;
        })
        reset({descriptions: descriptions});

    }


    useEffect(() => {
        if (!step1Completed()) navigate("../");
    });


    return (

        <form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
            <Grid container spacing={2} margin={2}>

                {fields && fields.map((description, index) => {
                    return (
                        <Grid item xs={12} key={index}>
                            <AntiqueDescriptionItem control={control} index={index}
                                                    errors={errors?.descriptions?.[index]} {...description}/>
                        </Grid>
                    );
                })}

                <Grid item xs={12}>
                    <Button startIcon={<ArrowBackIcon/>} onClick={goBack} variant={"outlined"}>
                        {t('back')}
                    </Button>
                    <Button onClick={onReset} startIcon={<DeleteIcon/>} variant={"outlined"}>
                        {t('reset')}
                    </Button>
                    <Button type="submit" startIcon={<ArrowForwardIcon/>} onClick={handleSubmit(onSubmit)}
                            variant={"contained"}>
                        {t('next')}
                    </Button>
                </Grid>
            </Grid>
        </form>

    )


}

export default Step2;