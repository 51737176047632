import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import AntiqueBackdrop from "../AntiqueBackdrop/AntiqueBackdrop";
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";

const SimpleForm = ({children, schema, defaultValues, submitPromise, translationNameSpace, disabledSubmit}) => {

    const { handleSubmit, formState:{ errors }, control, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues
    });

    const {t} = useTranslation();

    const [state, setState] = useState({errors : [], messages: [], sent: false, loading:false});

    const onError = (responseData) => {
        let errors = [];
        if(typeof responseData?.message !== 'undefined') errors.push(responseData.message);
        else{
            if(Array.isArray(responseData.errors))
            {
                responseData.errors.map(item => {
                    errors.push(item.toString());
                    return true;
                })
            }
            else errors = ["Unknown error"];
        }
        setState({errors : errors, messages: [], sent: false, loading:false});
    };

    const onSubmit = data => {
        setState({errors : [], messages: [], sent: false, loading:true});
        submitPromise(data)
            .then(res => {
                setState({errors : [], messages: [res.data], sent: true, loading:false});
            }).catch(e=>{
                if(typeof e.response === 'undefined') onError({errors : [e]});
                else onError(e.response.data);
        })
    }

    const onReset = () => {
        reset(defaultValues);
        setState({errors : [], messages: [], sent: false, loading:false});
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <AntiqueBackdrop loading={state.loading} color={"inherit"} text={t('sendingForm')}/>
            {state.errors.length ?
                (
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        <ul>
                            {state.errors.map((error, idx) => {
                                return <li key={idx}>{error}</li>
                            })}
                        </ul>
                    </Alert>
                ) :
                ''
            }
            {state.messages.length ?
                (
                    <>
                        <Alert severity="info">
                            <AlertTitle>{t('info')}</AlertTitle>
                            {state.messages.map((item, idx) => {
                                return <b key={idx}>{item}</b>
                            })}
                        </Alert>
                        <Button onClick={onReset} variant={"contained"}>
                            {t('send_another')}
                        </Button>
                    </>
                ) :
                ''
            }
            <Grid container spacing={2}>
                {state.sent || disabledSubmit ? '' :
                        <>
                            {
                                children.map((childElement, idx) => {
                                    const name = childElement.props.name;

                                    return (
                                        <Grid item xs={12} key={idx}>
                                            {React.cloneElement(childElement, {
                                                name: childElement.props.name,
                                                control: control,
                                                errors : errors,
                                                label : childElement.props.label,
                                                InputProps :
                                                    {
                                                        helperText : errors[name] ? t('forms.'+translationNameSpace+'.errors.'+name) : '',
                                                    }


                                            })}
                                        </Grid>
                                    );
                                })
                            }

                            {
                                state.loading ? '' :
                                <Grid item xs={12}>
                                    <Button onClick={onReset} variant={"outlined"}>
                                        {t('reset')}
                                    </Button>
                                    <Button onClick={handleSubmit(onSubmit)} variant={"contained"}>
                                        {t('submit')}
                                    </Button>

                                </Grid>
                            }
                        </>
                    }
            </Grid>
        </form>
    );
};

export default SimpleForm;
