import React from "react";
import { Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';

export function FormInput (props)
{

    const {  name, control, label,errors, disabled, InputProps } = props;

    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value, name}}) => (
                <TextField {...InputProps} disabled={disabled} onChange={onChange} value={value} label={label} name={name} error={errors && errors?.hasOwnProperty(name)}/>
            )}
        />
    );
}