import React from "react";
import {Box,Paper, Typography, MobileStepper, Button} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import {S3_IMAGE_URL} from "../../const";

const ItemImgGallery = ({images, id, name}) => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps =images.length;

    const {t} = useTranslation();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getImgSrc = () => {
        return S3_IMAGE_URL + 'items/item'+id+'/'+images[activeStep].normal;
    }

    const openImage = (event) => {
        window.open(getImgSrc())
        event.preventDefault()
    }

    return (
        <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                    bgcolor: 'background.default',
                }}
            >
                <Typography>{t('shop.imageGallery')}</Typography>
            </Paper>
            <Box sx={{ height: 320, width: "100%", p: 0 }} >
                <div style={{float:'left', position: 'absolute', padding:5}}>
                    <IconButton tooltip={t('shop.openInFull')} onClick={openImage}>
                        <OpenInNewIcon/>
                    </IconButton>
                </div>
                <img src={getImgSrc()} alt={name} height={"100%"} width={"100%"}/>
            </Box>
            <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        {t('next')}
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        {t('back')}
                    </Button>
                }
            />
        </Box>
    );
}

export default ItemImgGallery;
