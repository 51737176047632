import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";


export default function FormStepper(props) {

    const {t} = useTranslation();
    const {activeStep, steps, descriptions} = props;
    const completed = (index) => {return index <= activeStep;}

    return (
        activeStep < 0 ?
            <Box>Error</Box>
            :
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep}>
                    {Object.keys(steps).map((label, index) => {
                        return (
                            <Step key={label} completed={completed(index)}>
                                <StepLabel>{t(steps[label])}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep +1 === Object.keys(steps).length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            {t('AllStepsCompleted')}
                        </Typography>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>{t('Step')} {activeStep + 1}</Typography>
                        <Typography variant="h6">{t(descriptions[activeStep])}</Typography>

                    </React.Fragment>
                )}
            </Box>
    );
}