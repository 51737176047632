import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FormInput} from "../FormInput/FormInput";
import {useTranslation} from "react-i18next";
import {verifyCodeFor} from "../../services/cognito"
import {useState} from "react";
import AntiqueBackdrop from "../AntiqueBackdrop/AntiqueBackdrop";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const SignUpConfirmForm = () => {

    const schema = yup.object({
        email: yup.string().email().required(),
        code: yup.number().required(),
    }).required();

    const [signUpErrors, setSignUpErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [activated, setActivated] = useState(false)

    const {handleSubmit, control, formState: {errors}, reset} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {email:'',code:''}
    });

    const onSubmit = (data) => {
        setLoading(true)
        verifyCodeFor(data.email, data.code.toString()).then((r)=>{
            setLoading(false)
            if(r === 'SUCCESS') {
                setActivated(true)
            }
            else {
                setSignUpErrors(['The user was already activated'])
            }
        }).catch((errors)=>{
            setSignUpErrors([errors.toString()])
            setLoading(false)
        })
    }

    const [t] = useTranslation();

    const getLabel = (label) => {
        return t('forms.signUpForm.labels.'+label)
    }

    if(activated) {
        return (
            <Container component="main" maxWidth="xs">
                <Grid container>
                    <Grid>
                        <h5>User account activated</h5>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <AntiqueBackdrop loading={loading} color={"inherit"} text={t('sendingForm')}/>
            {signUpErrors.length ?
                (
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        <ul>
                            {signUpErrors.map((error, idx) => {
                                return <li key={idx}>{error}</li>
                            })}
                        </ul>
                    </Alert>
                ) :
                ''
            }
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t(getLabel('confirmationTitle'))}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormInput name={"email"} control={control} errors={errors}
                                       label={t(getLabel("emailAddress"))} InputProps={{
                                margin: "normal",
                                required: true,
                                disabled : loading,
                                fullWidth: true,
                                id: "email",
                                autoComplete: "email",
                                autoFocus: true
                            }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput name={"code"} control={control} errors={errors} label={t(getLabel("code"))} InputProps={{
                                margin:"normal",
                                required:true,
                                fullWidth:true,
                                type:"number",
                                id:"code",
                                disabled : loading,
                            }}/>
                        </Grid>

                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        {t(getLabel("confirm"))}
                    </Button>
                </Box>
            </Container>
        </form>
    );
}
export default SignUpConfirmForm