import React, {useState} from 'react'
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import GoogleIcon from '@mui/icons-material/Google';
import {signInWithGoogle} from "../../services/cognito";
import AntiqueBackdrop from "../AntiqueBackdrop/AntiqueBackdrop";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
const SignInWithGoogle = () => {

    const [t] = useTranslation();

    const [signInErrors, setSignInErrors] = useState([])

    const [loading, setLoading] = useState(false)



    function loginWithGoogle() {
        setLoading(true)

        return signInWithGoogle().catch((e)=>{
            setSignInErrors([e.toString()])
            setLoading(false)
        })


    }

    return (
                <>
                    {signInErrors.length ?
                        (
                            <Alert severity="error">
                                <AlertTitle>{t('error')}</AlertTitle>
                                <ul>
                                    {signInErrors.map((error, idx) => {
                                        return <li key={idx}>{error}</li>
                                    })}
                                </ul>
                            </Alert>
                        ) :
                        ''
                    }
                    <AntiqueBackdrop loading={loading} color={"inherit"} text={t('sendingForm')}/>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        style={{backgroundColor: "#21b6ae",}}
                        onClick={(e) => {
                            e.preventDefault();
                            loginWithGoogle()
                        }}
                    >

                        <GoogleIcon/>
                        {t('forms.signInForm.labels.signInWithGoogle')}
                    </Button>
                </>


    )
}
export default SignInWithGoogle