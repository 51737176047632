import React, {useEffect} from 'react';
import { Routes, Route } from 'react-router';
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

export const LocalizedRoutes = ({ children }) => {

    const { t, i18n } = useTranslation();

    const location = useLocation();

    useEffect(() => {

        window.gtag("event", "page_view", {
            page_path: location.pathname + location.search + location.hash,
            page_search: location.search,
            page_hash: location.hash,
        });
    }, [location]);
    /**
     *
     * @param path can be string, undefined or string array
     * @returns Localized string path or path array
     */
    function localizeRoutePath(path) {
        switch (typeof path) {
            case 'undefined':
                return undefined;
            case 'object':
                return path.map(() => `/${i18n.resolvedLanguage}` + t(path));
            default:
                if(path === '*') return path;
                let path2 = t(path)
                if(path2[path2.length-1] === '/' && path2.length > 1) path2 = path2 + '*'
                return path2
        }
    }

    /**
     * Apply localization to all routes
     * Also checks if all children elements are <Route /> components
     */
    return (
        <Routes>
            {
                children.map((child)=>{
                    return <Route key={child.props.path} path={localizeRoutePath(child.props.path)} element={child.props.element} />
                })
            }
        </Routes>
    );
};