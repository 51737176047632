import React, {useState} from "react";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {Alert, Grid, ImageList, LinearProgress, Stack} from "@mui/material";
import AntiqueImageListItem from "../AntiqueImageListItem/AntiqueImageListItem";
const AntiqueImageUploader = ({images, remove, update}) => {

    const getDefaultItem = () => {


        return {
            fileData : {
                size : 0,
                name : '',
                type : ''
            },
            content : '',
            index: 0,
            loading:false,
            progress:0
        };
    }

    const {t} = useTranslation();

    const [state, setState] = useState({loading:false,progress:0, images: images?images:[],  errors : []});

    const fileUpload = React.createRef();
    const showFileUpload = () => {
        fileUpload.current.click();
    }

    const getFormLabelTranslationFor = (name) => 'forms.addForm.labels.'+name;

    const deleteImage = (id) => {
        let newImages = state.images;



        remove(state.images[id], id).then(()=>{
            newImages.splice(id, 1)
            setState({
                images:newImages,
                loading: false,
                progress: 0,
                errors : []

            })
            update(state.images)
        })


    }


    function _arrayBufferToBase64( buffer ) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    function handleFiles(file) {

        if(file === undefined) return

        let copy = state.images;
        setState({loading:true, images: copy, progress: 1, errors: []})
        const local = getDefaultItem();

        if (!file.type.startsWith('image/')){ return local; }



        //if(file.size > 100000) { return local; }


        local.fileData.size = file.size;
        local.fileData.name = file.name;
        local.fileData.type = file.type;

        const reader = new FileReader();
        reader.onload = (function (e) {

            let newImages = state.images;
            newImages[newImages.length-1].content = _arrayBufferToBase64(e.target.result);
            setState({
                    images : newImages,
                    loading: false,
                    progress: 0,
                    errors: []
                })
            update(state.images)
            //setLoading(false)
        });
        reader.onprogress = (function (e){
            setState({
                images : state.images,
                loading: false,
                progress: e.loaded*100/e.total,
                errors : []
            })
        })
        reader.onloadstart = (function (){
            let newImages = state.images;
            newImages.push(local);
            setState({
                images : newImages,
                loading: false,
                progress: 0,
                errors : []
            })
        })
        reader.readAsArrayBuffer(file);

    }



    return (
        <Grid container>
            <Grid item xs={12} lg={12} style={{height:'10px'}}>
                {state.loading?<LinearProgress value={state.progress}/>:''}
            </Grid>
            {
                state.errors.length > 0 ?
                    <Grid item xs={12} lg={12}>
                        <Stack sx={{ width: '100%' }} spacing={1}>
                        {
                            state.errors.map((e)=>{
                                return <Alert severity="error">{e.toString()}</Alert>
                            })
                        }
                        </Stack>
                    </Grid>
                    :
                    ''
            }
            <Grid item xs={12} lg={12}>

                <ImageList
                    sx={{ width: 500, height: 200 }}
                    variant="quilted"
                    cols={4}
                    rowHeight={121}
                >
                    {state.images.map((item, idx) => {
                            return (
                                <AntiqueImageListItem  key={idx} cols={1} rows={1} image={item} id={idx} removeButtonAction={deleteImage}/>
                            );
                        })}
                </ImageList>
            </Grid>
            <Grid item xs={12} lg={12}>
                <input ref={fileUpload} type="file" accept="image/*" onChange={(evt)=>handleFiles(evt.target.files[0])} style={{display:'none'}}/>
                <Button onClick={()=>showFileUpload()}>{t(getFormLabelTranslationFor("select_image"))}</Button>
            </Grid>
        </Grid>
    )
}

export default AntiqueImageUploader