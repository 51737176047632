import React, {useContext} from 'react';
import {FormInput} from "../FormInput/FormInput";
import * as yup from "yup";
import axios from "axios";
import SimpleForm from "../SimpleForm/SimpleForm";
import {useTranslation} from "react-i18next";
import {REST_API_ENDPOINT} from "../../const";
import {UserContext} from "../../contexts/UserContext";

const schema = yup.object({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    id: yup.string().required()
}).required();


export default function BuyForm({id, name, price, currency, userId}) {


    const authUser = useContext(UserContext)

    const defaultValues = {
        firstName: authUser?.FamilyName? authUser.FamilyName : "",
        lastName: authUser?.GivenName? authUser.GivenName : "",
        email: authUser?.Email? authUser.Email : "",
        phone: authUser?.PhoneNumber? authUser.PhoneNumber : "",
        id : id
    };

    const translationNameSpace = "contactForm";
    const {t} = useTranslation();

    const getLabel = (name) => "forms."+translationNameSpace+".labels."+name;

    async function submitPromise(data) {
        data['name'] = name;
        data['price'] = price;
        data['currency'] = currency;
        return axios.post(REST_API_ENDPOINT+`/buy`,  data )
    }

    return (
        <SimpleForm disabledSubmit={authUser?.UserId === userId} schema={schema} defaultValues={defaultValues} submitPromise={submitPromise} translationNameSpace={translationNameSpace}>
            <FormInput name={"id"} InputProps={{readOnly: true}} label={t(getLabel("item"))}/>
            <FormInput name={"firstName"} label={t(getLabel("firstName"))}/>
            <FormInput name={"lastName"} label={t(getLabel("lastName"))}/>
            <FormInput name={"email"} label={t(getLabel("email"))}/>
            <FormInput name={"phone"} label={t(getLabel("phone"))}/>
        </SimpleForm>
    );
};
