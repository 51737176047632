import React, {useEffect} from 'react';
import Button from "@mui/material/Button";
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useLocation, useNavigate} from "react-router-dom";

const Step4 = () => {

    const navigate = useNavigate();
    const {t} = useTranslation();
    const { state } = useLocation();

    const step3Completed = () =>{
        return state !== null && state.result !==undefined && state.result.length > 0;
    }

    const startAgain = () => {
        navigate("../");
    }

    useEffect(() => {
        if(!step3Completed()) navigate("../");
    });

    return (
        <Grid container spacing={2} margin={2}>
            <Grid item xs={12}>{state !== null && state.result.length ?
                (
                    <Alert severity="info">
                        <AlertTitle>Info</AlertTitle>
                        {state.result.map((item, idx) => {
                            return <b key={idx}>{item}</b>
                        })}
                    </Alert>
                ) :
                <></>
            }
            </Grid>
            <Grid item xs={12}>
                <Button startIcon={<ArrowBackIcon />} onClick={startAgain} variant={"contained"}>
                    {t('send_another')}
                </Button>
            </Grid>
        </Grid>
    );
}

export default Step4;